import jwt from "jsonwebtoken";

export function tokenIsExpired() {
    const token = sessionStorage.getItem('token');
    if (token) {
        const decodedJwt = jwt.decode(token);
        return decodedJwt.exp * 1000 < new Date().getTime();
    } else {
        return false;
    }
}