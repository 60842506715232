import React from "react";
import PropTypes from 'prop-types';
import { Button, Icon, Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const DialogModal = ({ header, content, trigger, size, onClickAction, yesLable, noLabel }) => {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();

    const onClick = () => {
        onClickAction();
        setOpen(false);
    };

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            closeOnDimmerClick={false}
            dimmer='blurring'
            size={size}
            open={open}
            trigger={trigger}
            >
            <Modal.Header>{t(header)}</Modal.Header>
            <Modal.Content>
                <p>{t(content)}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={() => setOpen(false)}>
                    <Icon name='remove' /> {t(noLabel)}
                </Button>
                <Button primary onClick={onClick}>
                    <Icon name='checkmark' /> {t(yesLable)}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};


DialogModal.defaultProps = {
    size: 'tiny',
    yesLable: 'common.actions.yes',
    noLabel: 'common.actions.no',
};

DialogModal.propTypes = {
    header: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    trigger: PropTypes.element.isRequired,
    size: PropTypes.oneOf(['mini', 'tiny', 'small', 'large']),
    onClickAction: PropTypes.func.isRequired,
    yesLable: PropTypes.string,
    noLabel: PropTypes.string
}

export default DialogModal;