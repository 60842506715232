import React from "react";
import ErrorPage from "./ErrorPage";
import { useTranslation } from "react-i18next";

const UnauthorizedAccess = () => {
    const { t } = useTranslation();

    return (
        <ErrorPage icon='ban' iconColor='red' message={t('common.errors.unauthorizedAccess')} />
    );
};

export default UnauthorizedAccess;