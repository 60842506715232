import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Segment, Tab } from "semantic-ui-react";
import { hasRole } from "../utils/userRolesUtils";

import Page from "./Page";
import TabUsers from "../components/administration/tabs/TabUsers";
import TabUserActivityLog from "../components/administration/tabs/TabUserActivityLog";

const Administration = () => {
  const { t } = useTranslation();
  const authData = useSelector(state => state.auth);
  const { roles } = authData.user;

  const sections = [
    { key: 'Dashboard', content: t('breadcrumb.dashboard'), link: true, as: Link, to: '/'},
    { key: 'administration', content: t('breadcrumb.administration'), active: true }
  ];

  const getVisiblePanes = () => {
    let panes = [];

    if (hasRole(roles, 'admin', 'users')){
      panes.push({ menuItem: t('administration.users.tabTitle'), render: () => <TabUsers /> });
      panes.push({ menuItem: t('administration.userActivityLog.tabTitle'), render: () => <TabUserActivityLog /> });
    }

    return panes;
  }
    
  return (
    <Page sections={sections}>
      <Segment basic>
        {<Tab panes={getVisiblePanes()} />}
      </Segment>
    </Page>
  );
};

export default Administration;