import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Button,Grid, Icon, Segment, Statistic } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const ErrorPage = ({ message, icon, iconColor }) => {
    const { t } = useTranslation();

    return (
        <Segment basic>
            <Grid centered style={{ marginTop: '10em' }}>
                <Grid.Row>
                    <Statistic size='huge'>
                        <Statistic.Value>
                            <Icon name={icon} color={iconColor}/>
                        </Statistic.Value>
                        <Statistic.Label>{message}</Statistic.Label>
                    </Statistic>
                </Grid.Row>
                <Grid.Row>
                    <Button primary as={Link} to='/'>{t('common.actions.goToDashboard')}</Button>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

ErrorPage.propTypes = {
    message: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    iconColor: PropTypes.string
};

export default ErrorPage;