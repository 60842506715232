import quercusApi from '../../apis/quercusApi';
import { handleError } from '../../helpers/handleError';
import {
    LPR5_FETCH_MAC_RANGES,
    LPR5_FETCH_MAC_RANGES_REQUEST,
    LPR5_FETCH_MAC_RANGES_ERROR,
    LPR5_FETCH_MACS_COUNT,
    LPR5_FETCH_MACS_COUNT_REQUEST,
    LPR5_FETCH_MACS_COUNT_ERROR,
    LPR5_CREATE_MAC_RANGE,
    LPR5_CREATE_MAC_RANGE_REQUEST,
    LPR5_CREATE_MAC_RANGE_ERROR
} from './types';

export const fetchMacRanges = ({ offset, limit }) => async (dispatch) => {
    dispatch({ type: LPR5_FETCH_MAC_RANGES_REQUEST });
    try {
        let query = {
            params: {
                limit: limit,
                offset: offset 
            }
        };

        const response = await quercusApi.get('/lprPS/macRanges', query);
        dispatch({ type: LPR5_FETCH_MAC_RANGES, payload: response.data });
    } catch (error) {
        dispatch({ type: LPR5_FETCH_MAC_RANGES_ERROR, payload: handleError(error) });
    }
};

export const fetchMacsCount = () => async (dispatch) => {
    dispatch({ type: LPR5_FETCH_MACS_COUNT_REQUEST });
    try {
        const response = await quercusApi.get('/lprPS/macRanges/macs/count');
        dispatch({ type: LPR5_FETCH_MACS_COUNT, payload: response.data });
    } catch (error) {
        dispatch({ type: LPR5_FETCH_MACS_COUNT_ERROR, payload: handleError(error) });
    }
};

export const createMacRange = (macRange) => async (dispatch) => {
    dispatch({ type: LPR5_CREATE_MAC_RANGE_REQUEST });
    try {
        const response = await quercusApi.post('/lprPS/macRanges', macRange);
        dispatch({ type: LPR5_CREATE_MAC_RANGE, payload: response.data });
    } catch (error) {
        dispatch({ type: LPR5_CREATE_MAC_RANGE_ERROR, payload: handleError(error) });
    }
};