import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Label, Menu, Table } from "semantic-ui-react";
import _ from "lodash";
import moment from "moment";

import { useTable, TableProvider } from "../../../providers/tableProvider";
import { hasRole } from "../../../utils/userRolesUtils";
import TablePaginated from "../../core/TablePaginated";
import TableCellActions from "../../core/TableCellActions";
import FilterProducts from "../filters/FilterProducts";
import FilterPackages from "../filters/FilterPackages";
import FilterReleaseStatus from "../filters/FilterReleaseStatus";

import { fetchReleases } from "../../../actions/releases/releasesAction"

function TableComposition () {
    let table = useTable();
    const rsData = useSelector(state => state.releases);
    const authData = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [selectedProduct, setSelectedProduct] = React.useState('');
    const { roles } = authData.user;
    const { t } = useTranslation();
  
    React.useEffect(() => {
      loadReleases(!(hasRole(roles, 'releases', 'admin') || hasRole(roles, 'releases', 'developer')));
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table.filters]);

    const loadReleases = (productionOnly) => {
      dispatch(fetchReleases(table.filters, productionOnly));
    }

    const handleFilterChanged = (field, value) => {
      if (!value || value.length === 0){
        if (field === 'productId'){
          table.onDeleteFilters(['productId', 'packageId']);
        } else {
          table.onDeleteFilter(field);
        }
      } else {
        table.onAddFilter({
            field: field,
            value: value 
        });
      }

      if (field === 'productId'){
        setSelectedProduct(value);
      }
    };

    const showStatus = (status, key) => {
      const statusList = {
        "QA": 'green', 
        "STAGE": 'yellow',
        "PRODUCTION": "blue"
      };

      return (
        <Table.Cell key={key}>
          <Label key={status.toUpperCase()} color={statusList[status.toUpperCase()]}>{status.toUpperCase()}</Label>
        </Table.Cell>
      );
    }

    const getActions = (releaseId) => {
      return [
        { 
          key: 'view',
          link: `/releases/${releaseId}`,
          label: t('releases.list.table.viewRelease'),
          icon: 'eye'
        }
      ];
    }

    const headerRow = [
      t('releases.list.table.product'), 
      t('releases.list.table.package'),
      t('releases.list.table.version'),
      t('releases.list.table.releaseDate'),
      t('releases.list.table.status'),
      t('releases.list.table.statusChangeDate'),
      t('releases.list.table.releaseCandidate'),
      t('common.labels.actions')
    ];

    const renderBodyRow = ({ id, packageDescription, productDescription, version, releaseDate, status, statusChangeDate, releaseCandidate }, i) => ({
      key: `row-${i}`,
      cells: [
        { key: 'productId', content: productDescription },
        { key: 'packageId', content: packageDescription },
        { key: 'version', content: version },
        { key: 'releaseDate', content: releaseDate ? moment(releaseDate).format('DD-MM-YYYY HH:mm:ss') : '-' },
        showStatus(status, i),
        { key: 'statusChangeDate', content: statusChangeDate ? moment(statusChangeDate).format('DD-MM-YYYY HH:mm:ss') : '-' },
        { key: 'releaseCandidate', content: `RC${releaseCandidate}` },
        { key: 'actions', as: TableCellActions, actions: getActions(id) }
      ]
    });

    return (
      <div>
          <Menu secondary>
            <Menu.Menu>
              <Menu.Item>
                <FilterProducts 
                      onProductSelect={handleFilterChanged}
                      loading={rsData.loading}/>
              </Menu.Item>
              <Menu.Item>
                <FilterPackages
                      productId={selectedProduct} 
                      onPackageSelect={handleFilterChanged}
                      loading={rsData.loading}/>
              </Menu.Item>
              {(hasRole(roles, 'releases', 'admin') || hasRole(roles, 'releases', 'developer')) && 
                <Menu.Item>
                    <FilterReleaseStatus 
                        onStatusSelect={handleFilterChanged}
                        loading={rsData.loading}/>
                </Menu.Item>
              }
            </Menu.Menu>
          </Menu>    

          <TablePaginated
            headerRow={headerRow}
            renderBodyRow={renderBodyRow}
            tableData={_.values(rsData.releases)}
            loading={rsData.loading}
            totalItems={rsData.totalItems}
            setPagination={table.onPaginationChange}
          />
      </div>
    );
}

const TableReleases = () => <TableProvider><TableComposition /></TableProvider>

export default TableReleases;