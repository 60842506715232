import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Checkbox, Label, Menu, Table } from "semantic-ui-react";
import _ from "lodash";
import moment from "moment";
import { saveAs } from "file-saver";

import { useTable, TableProvider } from "../../../providers/tableProvider";
import { useLoader } from "../../../providers/loaderProvider";
import { useAlert } from "../../../providers/alertsProvider";
import { hasRole } from "../../../utils/userRolesUtils";
import TablePaginated from "../../core/TablePaginated";
import TableCellActions from "../../core/TableCellActions";

import { fetchFiles } from "../../../actions/releases/releasesFilesAction";
import { getDownloadFileFromReleaseService } from "../../../helpers/handleDownloadFile";

function TableComposition ({ releaseId }) {
    let table = useTable();
    const { addError } = useAlert();
    const { showLoader, hideLoader } = useLoader();
    const dataFiles = useSelector(state => state.releasesFiles);
    const authData = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [showPending, setShowPending] = React.useState(false);
    const { roles } = authData.user;
    const { t } = useTranslation();
  
    React.useEffect(() => {
      loadFiles();
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table.filters, showPending]);

    const loadFiles = () => {
      dispatch(fetchFiles(table.filters, releaseId, showPending));
    }

    const showStatus = (status, key) => {
      const statusList = {
        "PENDING": 'red', 
        "UPLOADED": 'green'
      };

      return (
        <Table.Cell key={`status${key}`}>
          <Label key={status.toUpperCase()} color={statusList[status.toUpperCase()]}>{status.toUpperCase()}</Label>
        </Table.Cell>
      );
    }

    const showTag = (tag, key) => {
      const statusList = {
        "UPDATE": 'blue', 
        "RELEASE-NOTES": 'teal'
      };

      return (
        <Table.Cell key={`tag${key}`}>
          <Label tag size='small' key={tag.toUpperCase()} color={statusList[tag.toUpperCase()]}>{tag.toUpperCase()}</Label>
        </Table.Cell>
      );
    }

    const onDownloadClick = (fileId) => {
      const file = dataFiles.files[fileId];
      showLoader(t('releases.files.actions.downloadFileLoader'));
      getDownloadFileFromReleaseService(file.url)
        .then(response => {
          saveAs(response.data, file.fileName);
          hideLoader();
        })
        .catch(error => {
          addError(t('releases.files.actions.errorDownloadFile'), `${t('releases.files.actions.errorMsgDownloadFile')} ${fileId}`);
          hideLoader();
        });
    };

    const getActions = (isPending) => {
      return [
        { 
          key: 'download', 
          label: t('releases.files.actions.downloadFile'), 
          icon: 'download',
          disabled: isPending, 
          handleClick: onDownloadClick }
      ];
    }

    const headerRow = [
      t('releases.files.table.fileName'),
      t('releases.files.table.status'), 
      t('releases.files.table.tag'),
      t('releases.files.table.modifiedDate'),
      t('common.labels.actions')
    ];

    const renderBodyRow = ({ fileId, fileName, tag, status, modifiedDate }, i) => ({
      key: `row-${i}`,
      cells: [
        { key: 'fileName', content: fileName },
        showStatus(status, i),
        tag ? showTag(tag, i) : '',
        { key: 'modifiedDate', content: modifiedDate ? moment(modifiedDate).format('DD-MM-YYYY HH:mm:ss') : '-' },
        { key: 'actions', as: TableCellActions, actions: getActions(status.toUpperCase() === "PENDING"), rowId: fileId }
      ]
    });

    return (
      <div>
          {(hasRole(roles, 'releases', 'admin') || hasRole(roles, 'releases', 'developer')) &&
            <Menu secondary>
              <Menu.Menu position='right'>
                <Menu.Item>
                  <Checkbox
                    label={t('releases.files.filters.showPendingFiles')}
                    toggle
                    onChange={(e, data) => setShowPending(data.checked)}
                    checked={showPending}
                  />
                </Menu.Item>
              </Menu.Menu>
            </Menu>
          }   

          <TablePaginated
            headerRow={headerRow}
            renderBodyRow={renderBodyRow}
            tableData={_.values(dataFiles.files)}
            loading={dataFiles.loading}
            totalItems={dataFiles.totalItems}
            setPagination={table.onPaginationChange}
            defaultItemsPerPage={5}
          />
      </div>
    );
}

const TableFiles = (props) => <TableProvider><TableComposition releaseId={props.releaseId}/></TableProvider>

export default TableFiles;