import React from "react";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { Button, Input, Modal } from "semantic-ui-react";

const DialogMacRange = ({ trigger, onClickAction }) => {
    const [open, setOpen] = React.useState(false)
    const [macRange, setMacRange] = React.useState({ minAddress: '', maxAddress: '' })
    const { t } = useTranslation();

    const onOpen = () => {
        setOpen(true);
        setMacRange({ minAddress: '', maxAddress: '' });
    };

    const onClick = () => {
        onClickAction(macRange);
        setOpen(false);
    };

    const handleMinAddressOnChange = (e, { value }) => {
        setMacRange({ minAddress: value, maxAddress: macRange.maxAddress });
    };

    const handleMaxAddressOnChange = (e, { value }) => {
        setMacRange({ minAddress: macRange.minAddress, maxAddress: value });
    };

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={onOpen}
            closeOnDimmerClick={false}
            dimmer='blurring'
            size='small'
            open={open}
            trigger={trigger}
            >
            <Modal.Header>{t('lprPs.macRange.create.header')}</Modal.Header>
            <Modal.Content>
                {t('lprPs.macRange.create.contentTitle')}:
                <div style={{ marginTop: '0.8em' }}>
                    {t('lprPs.macRange.create.minAddress')}:
                    <Input
                        placeholder='XX:XX:XX:XX:XX:XX'
                        value={macRange.minAddress}
                        onChange={handleMinAddressOnChange}
                        style={{ marginLeft: '0.7em' }}/>
                </div>
                <div style={{ marginTop: '0.8em' }}>
                    {t('lprPs.macRange.create.maxAddress')}:
                    <Input
                        placeholder='XX:XX:XX:XX:XX:XX'
                        value={macRange.maxAddress}
                        onChange={handleMaxAddressOnChange}
                        style={{ marginLeft: '0.5em' }}/>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={() => setOpen(false)}>
                    {t('common.actions.cancel')}
                </Button>
                <Button onClick={onClick} primary disabled={macRange.minAddress.length === 0 || macRange.maxAddress.length === 0}>
                    {t('common.actions.save')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

DialogMacRange.propTypes = {
    trigger: PropTypes.element.isRequired,
    onClickAction: PropTypes.func.isRequired,
}

export default DialogMacRange;