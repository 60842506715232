import React from "react";
import ErrorPage from "./ErrorPage";
import { useTranslation } from "react-i18next";

const ResourceNotFound = () => {
    const { t } = useTranslation();

    return (
        <ErrorPage icon='warning sign' iconColor='yellow' message={t('common.errors.resourceNotFound')} />
    );
};

export default ResourceNotFound;