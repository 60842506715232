import React from "react";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { Header, Image, Table } from "semantic-ui-react";
import defaultProfile from '../../images/default-profile.png';

const TableCellProfile = ({ profileUrl, profileName }) => {
  const { t } = useTranslation();

  return (
    <Table.Cell>
      <Header as='h4' image>
        <Image src={profileUrl ? profileUrl : defaultProfile} rounded size='small' />
        <Header.Content>
          {profileName ? profileName : t('common.labels.unknown')}
        </Header.Content>
      </Header>
    </Table.Cell>
  );
};

TableCellProfile.defaultProps = {
  profileUrl: defaultProfile
};

TableCellProfile.propTypes = {
  profileUrl: PropTypes.string,
  profileName: PropTypes.string.isRequired
};

export default TableCellProfile;