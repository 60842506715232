import { createContext, useContext, useState } from "react";

/**
 * Define context
 */
const loaderContext = createContext();
/**
 * 
 * @returns 
 * Use context
 */
function useLoader() {
  return useContext(loaderContext);
}
/**
 * 
 * @returns 
 * Loader Provider
 */
function useLoaderProvider() {
  const [loader, setLoader] = useState({ active: false, message: null });

  const showLoader = (text) => {
    setLoader({
      active: true,
      message: text ? text : 'Loading'
    })
  };

  const hideLoader = () => {
    setLoader({
      active: false,
      message: null
    })
  };

  return {
    loader,
    showLoader,
    hideLoader
  };
}
/**
 * 
 * @param {*} param0 
 * @returns 
 */
function LoaderProvider({ children }) {
  const loader = useLoaderProvider();
  return (
    <loaderContext.Provider value={loader}>
      {children}
    </loaderContext.Provider>
  );
}
export {
  LoaderProvider,
  useLoader
}