import React from "react";
import { Tab } from "semantic-ui-react";
import TableReleases from "../tables/TableReleases";

const TabReleases = () => {
  return (
    <Tab.Pane>
      <TableReleases/>
    </Tab.Pane>
  );
};

export default TabReleases;