import { 
    SC_FETCH_VERSION,
    SC_FETCH_VERSION_REQUEST, 
    SC_FETCH_VERSION_ERROR,
    SC_GET_SERIAL_NUMBER,
    SC_GET_SERIAL_NUMBER_REQUEST,
    SC_GET_SERIAL_NUMBER_ERROR,
    SC_INCREMENT_BATCH,
    SC_INCREMENT_BATCH_REQUEST,
    SC_INCREMENT_BATCH_ERROR
} from '../../actions/spotControl/types';
  
  const INTIAL_STATE = {
    loading: false,
    error: null,
    version: null,
    configuration: null,
    batch: null
  };
  
  /* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
  export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
      case SC_FETCH_VERSION_REQUEST:
        return { ...state, loading: true, version: null, error: null, batch: null };
      case SC_FETCH_VERSION:
        return { ...state, loading: false, version: action.payload, error: null, batch: null };
      case SC_FETCH_VERSION_ERROR:
        return { ...state, loading: false, version: null, error: action.payload, batch: null };

      case SC_GET_SERIAL_NUMBER_REQUEST:
        return { ...state, loading: true, configuration: null, error: null, batch: null };
      case SC_GET_SERIAL_NUMBER:
        return { ...state, loading: false, configuration: action.payload, error: null, batch: null };
      case SC_GET_SERIAL_NUMBER_ERROR:
        return { ...state, loading: false, configuration: null, error: action.payload, batch: null };
      
      case SC_INCREMENT_BATCH_REQUEST:
        return { ...state, loading: true, error: null, batch: null };
      case SC_INCREMENT_BATCH:
        return { ...state, loading: false, error: null, batch: action.payload };
      case SC_INCREMENT_BATCH_ERROR:
        return { ...state, loading: false, error: action.payload, batch: null };
      
      default:
        return state;
    }
  };