import { createContext, useContext, useState } from "react";

/**
 * Define context
 */
const alertContext = createContext();
/**
 * 
 * @returns 
 * Use context
 */
function useAlert() {
  return useContext(alertContext);
}
/**
 * 
 * @returns 
 * Alert Provider
 */
function useAlertProvider() {
  const [alert, setAlert] = useState(null);

  const addError = (header, message) => {
    setAlert({
      error: true,
      success: false,
      header: header,
      message: message
    })
  };

  const addSuccess = (header, message) => {
    setAlert({
      error: false,
      success: true,
      header: header,
      message: message
    })
  };

  const removeAlert = () => setAlert(null);

  return {
    alert,
    addError,
    addSuccess,
    removeAlert
  };
}
/**
 * 
 * @param {*} param0 
 * @returns 
 */
function AlertProvider({ children }) {
  const alert = useAlertProvider();
  return (
    <alertContext.Provider value={alert}>
      {children}
    </alertContext.Provider>
  );
}
export {
  AlertProvider,
  useAlert
}