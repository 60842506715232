import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FcGoogle } from "react-icons/fc";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useTranslation } from "react-i18next";
import { Button, Grid, Image, Message, Segment } from "semantic-ui-react";

import quercusLogo from '../images/logo_Quercus.png';
import { signInMethod } from '../actions/core/authAction';

const Login = () => {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const authData = useSelector(state => state.auth);

    const onSuccess = (response) => {
        if (!response.clientId || !response.credential) {
            return
        }
        setLoading(true);
        dispatch(signInMethod(response));
    }

    const onFailure = () => {
        setError(true);
    };

    return (
        <Grid
            textAlign="center"
            style={{height: "100vh"}}
            verticalAlign="middle">

            <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
                <Grid.Column style={{maxWidth: 450}}>
                    <Image src={quercusLogo}/>
                    <Segment>
                        {(loading) &&
                            <Button loading={loading} style={{backgroundColor: 'white'}}/>
                        }
                        <GoogleLogin
                            render={(renderProps) => (
                                <Button
                                    type="button"
                                    loading={loading}
                                    onClick={renderProps.onClick}
                                    disabled={renderProps.disabled}
                                >
                                    <FcGoogle className=""/>
                                    {t('login.signInGoogle')}
                                </Button>)}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            cookiePolicy="single_host_origin"
                            width={400}
                        />
                    </Segment>
                    {(error || authData.registerError) &&
                        <Message negative>
                            <Message.Header>{t('login.error')}</Message.Header>
                            <p>{t('login.errorDesc')}</p>
                        </Message>
                    }
                </Grid.Column>
            </GoogleOAuthProvider>
        </Grid>);
}

export default Login;