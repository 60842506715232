import React from "react";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Grid, Modal } from "semantic-ui-react";

import { userRoles } from "../../../utils/userRolesUtils";

const DialogEditUser = ({ trigger, onSave, user }) => {
    const [open, setOpen] = React.useState(false);
    const [roles, setRoles] = React.useState([]);
    const { t } = useTranslation();

    const onOpen = () => {
        setOpen(true);
        setRoles(user.roles ? user.roles : []);
    };

    const onClick = () => {
        onSave({ ...user, roles: roles });
        setOpen(false);
    };

    const handleRolesOnChange = (e, { value }) => {
        setRoles(value);
    };

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={onOpen}
            closeOnDimmerClick={false}
            dimmer='blurring'
            size='small'
            open={open}
            trigger={trigger}
            >
            <Modal.Header>{t('administration.users.actions.edit.header')}</Modal.Header>
            <Modal.Content>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={2}>
                            {t('administration.users.actions.edit.user')}:
                        </Grid.Column>
                        <Grid.Column width={13}>
                            {user ? user.name : ''}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={2}>
                            {t('administration.users.actions.edit.email')}:
                        </Grid.Column>
                        <Grid.Column width={13}>
                            {user ? user.email : ''}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={2}>
                            {t('administration.users.actions.edit.roles')}:
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <Dropdown
                                clearable
                                multiple
                                search
                                selection
                                options={userRoles}
                                value={roles}
                                onChange={handleRolesOnChange}
                                placeholder={t('administration.users.actions.edit.selectRoles')}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={() => setOpen(false)}>
                    {t('common.actions.cancel')}
                </Button>
                <Button onClick={onClick} primary disabled={false}>
                    {t('common.actions.save')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

DialogEditUser.propTypes = {
    trigger: PropTypes.element.isRequired,
    onSave: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
}

export default DialogEditUser;