export const RELEASES_FETCH_RELEASE = 'RELEASES_FETCH_RELEASE';
export const RELEASES_FETCH_RELEASE_REQUEST = 'RELEASES_FETCH_RELEASE_REQUEST';
export const RELEASES_FETCH_RELEASE_ERROR = 'RELEASES_FETCH_RELEASE_ERROR';

export const RELEASES_FETCH_RELEASES = 'RELEASES_FETCH_RELEASES';
export const RELEASES_FETCH_RELEASES_REQUEST = 'RELEASES_FETCH_RELEASES_REQUEST';
export const RELEASES_FETCH_RELEASES_ERROR = 'RELEASES_FETCH_RELEASES_ERROR';

export const RELEASES_FETCH_FILES = 'RELEASES_FETCH_FILES';
export const RELEASES_FETCH_FILES_REQUEST = 'RELEASES_FETCH_FILES_REQUEST';
export const RELEASES_FETCH_FILES_ERROR = 'RELEASES_FETCH_FILES_ERROR';

export const RELEASES_FETCH_PRODUCTS = 'RELEASES_FETCH_PRODUCTS';
export const RELEASES_FETCH_PRODUCTS_REQUEST = 'RELEASES_FETCH_PRODUCTS_REQUEST';
export const RELEASES_FETCH_PRODUCTS_ERROR = 'RELEASES_FETCH_PRODUCTS_ERROR';

export const RELEASES_CREATE_PRODUCT = 'RELEASES_CREATE_PRODUCT';
export const RELEASES_CREATE_PRODUCT_REQUEST = 'RELEASES_CREATE_PRODUCT_REQUEST';
export const RELEASES_CREATE_PRODUCT_ERROR = 'RELEASES_CREATE_PRODUCT_ERROR';

export const RELEASES_FETCH_PACKAGES = 'RELEASES_FETCH_PACKAGES';
export const RELEASES_FETCH_PACKAGES_REQUEST = 'RELEASES_FETCH_PACKAGES_REQUEST';
export const RELEASES_FETCH_PACKAGES_ERROR = 'RELEASES_FETCH_PACKAGES_ERROR';

export const RELEASES_CREATE_PACKAGE = 'RELEASES_CREATE_PACKAGE';
export const RELEASES_CREATE_PACKAGE_REQUEST = 'RELEASES_CREATE_PACKAGE_REQUEST';
export const RELEASES_CREATE_PACKAGE_ERROR = 'RELEASES_CREATE_PACKAGE_ERROR';