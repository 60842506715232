import React from "react";
import { useSelector } from "react-redux";
import { Header, Image, Menu } from "semantic-ui-react";

import quercusLogo from '../images/logo_Quercus.png';
import ButtonLogout from './ButtonLogout';

const HeaderMenu = () => {
  const authData = useSelector(state => state.auth);

  if (!authData.isSignedIn){
    return null;
  }

  return (
      <Menu>
          <Menu.Item header>
              <Image size='tiny' src={quercusLogo} style={{ marginRight: '1.5em' }} />
          </Menu.Item>
          {process.env.REACT_APP_ENVIRONMENT && 
            <Menu.Item>
                <Header as='h2' color='red'>{process.env.REACT_APP_ENVIRONMENT}</Header>
            </Menu.Item>
          }
          <Menu.Menu position='right'>
              <Menu.Item>
                {authData.user.name}
                  <Image src={authData.user.imageUrl} avatar style={{ marginLeft: '1.5em' }} referrerPolicy="no-referrer"/>
              </Menu.Item>

              <Menu.Item>
                  <ButtonLogout />
              </Menu.Item>
          </Menu.Menu>
      </Menu>
  );
};

export default HeaderMenu;