import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { saveAs } from "file-saver";

import { useTable, TableProvider } from "../../../providers/tableProvider";
import { useAlert } from "../../../providers/alertsProvider";
import { useLoader } from "../../../providers/loaderProvider";
import TablePaginated from "../../core/TablePaginated";
import TableCellActions from "../../core/TableCellActions";
import Filter from "../../core/Filter";

import { fecthProductions } from "../../../actions/lpr/lprProductionsAction";
import { getDownloadFile } from "../../../helpers/handleDownloadFile";

function TableComposition () {
    let table = useTable();
    const lpr5Productions = useSelector(state => state.lpr5Productions);
    const dispatch = useDispatch();
    const { addError } = useAlert();
    const { showLoader, hideLoader } = useLoader();
    const { t } = useTranslation();
    
    React.useEffect(() => {
      loadProductions();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table.filters]);

    React.useEffect(() => {
      if (lpr5Productions.error){
        addError(lpr5Productions.error.error, lpr5Productions.error.message);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lpr5Productions.error]);

    const loadProductions = () => {
      dispatch(fecthProductions(table.filters));
    }

    const onLogsClick = (productionId) => {
      const url = `/lprPS/lpr/production/${productionId}/logs`;
      showLoader(t('lprPs.productions.actions.downloadLogsLoader'));
      getDownloadFile(url)
        .then(response => {
          saveAs(response.data, `logs_lpr_production_${productionId}.zip`);
          hideLoader();
        })
        .catch(error => {
          addError(t('lprPs.productions.actions.errorDownloadLogs'), `${t('lprPs.productions.actions.errorMsgDownloadLogs')} ${productionId}`);
          hideLoader();
        });
    };

    const getActions = (disabledLogs) => {
      return [
        { key: 'logs', label: t('lprPs.productions.actions.downloadLogs'), icon: 'file alternate outline', handleClick: onLogsClick, disabled: disabledLogs }
      ];
    }

    const filters = [
      { key: 1, text: t('lprPs.productions.filter.serialNumber'), value: 'serialNumber', field: 'serialNumber', placeholder: t('lprPs.productions.filter.serialNumberPlaceholder'), type: 'input' },
      { key: 2, text: t('lprPs.productions.filter.producer'), value: 'producer', field: 'prodProducer', placeholder: t('lprPs.productions.filter.producerPlaceholder'), type: 'input' },
      { key: 3, text: t('lprPs.productions.filter.license'), value: 'license', field: 'license', type: 'select', placeholder: t('lprPs.productions.filter.licensePlaceholder'), options: [{ key: 1, text: 'Basic', value: 'Basic' }, { key: 2, text: 'Standard', value: 'Standard' }, { key:3, text: 'Pro', value: 'Pro' }] },
      { key: 4, text: t('lprPs.productions.filter.lprCountries'), value: 'lprCountries', field: 'lprCountries', type: 'select', placeholder: t('lprPs.productions.filter.lprCountriesPlaceholder'), options: [{ key: 1, text: 'NA', value: 'NA' }, { key: 2, text: 'NoNA', value: 'NoNA' }, { key:3, text: 'NoNA-Lite', value: 'NoNA-Lite' }, { key:4, text: 'SPL', value: 'SPL' }, { key:5, text: 'Worldwide', value: 'Worldwide' }] },
      { key: 5, text: t('lprPs.productions.filter.product'), value: 'product', field: 'product', type: 'select', placeholder: t('lprPs.productions.filter.productPlaceholder'), options: [{ key: 1, text: 'Smart LPR5', value: 'LPR5' }, { key: 2, text: 'Smart LPR6', value: 'LPR6' }] },
      { key: 6, text: t('lprPs.productions.filter.result'), value: 'result', field: 'result', type: 'select', placeholder: t('lprPs.productions.filter.resultPlaceholder'), options: [{ key: 1, text: 'Success', value: true }, { key: 2, text: 'Fail', value: false }] },
      { key: 7, text: t('lprPs.productions.filter.productionDateBefore'), value: 'timestampBefore', field: 'prodTimestamp', type: 'dateBefore' },
      { key: 8, text: t('lprPs.productions.filter.productionDateAfter'), value: 'timestampAfter', field: 'prodTimestamp', type: 'dateAfter' },
      { key: 9, text: t('lprPs.productions.filter.productionDateBetween'), value: 'timestamp', field: 'prodTimestamp', type: 'dateBetween'}
    ];

    const headerRow = [
      t('lprPs.productions.table.serialNumber'),
      t('lprPs.productions.table.productionDate'),
      t('lprPs.productions.table.producer'),
      t('lprPs.productions.table.license'),
      t('lprPs.productions.table.lprCountries'),
      t('lprPs.productions.table.macAddress'),
      t('lprPs.productions.table.result'),
      t('lprPs.productions.table.systemFsVersion'),
      t('common.labels.actions')];

    const renderBodyRow = ({ id, serialNumber, timestamp, producer, license, lprCountries, macAddress, result, systemFSVersion, log }, i) => ({
      key: `row-${i}`,
      cells: [
        serialNumber || '-',
        timestamp ? moment(timestamp*1000).format('DD-MM-YYYY HH:mm:ss') : '-',
        producer,
        license,
        lprCountries,
        macAddress,
        result ? t('common.labels.success') : t('common.labels.fail'),
        systemFSVersion,
        { key: 'actions', as: TableCellActions, actions: getActions(log && log.length > 0 ? false : true), rowId: id }
      ],
    });

    return (
      <div>
          <Filter
            filters={filters}
            onAddFilter={table.onResetFilter}
            onClearFilters={table.onClearFilters}
            loading={lpr5Productions.loading}
          />
          <TablePaginated
            headerRow={headerRow}
            renderBodyRow={renderBodyRow}
            tableData={lpr5Productions.productions}
            loading={lpr5Productions.loading}
            totalItems={lpr5Productions.totalItems}
            setPagination={table.onPaginationChange}
          />
      </div>
    );
}

const TableLprProductions = () => <TableProvider><TableComposition /></TableProvider>

export default TableLprProductions;