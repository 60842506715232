import React from "react";
import { Tab } from "semantic-ui-react";

import TableLpr5Macs from "../tables/TableLpr5Macs";

const TabLpr5Macs = () => {
  return (
    <Tab.Pane>
        <TableLpr5Macs />
    </Tab.Pane>
  );
};

export default TabLpr5Macs;