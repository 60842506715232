import _ from "lodash";
import {
    DEVICES_STORAGE_FETCH_FILES,
    DEVICES_STORAGE_FETCH_FILES_REQUEST,
    DEVICES_STORAGE_FETCH_FILES_ERROR
} from '../../actions/devicesStorage/types';

  const INTIAL_STATE = {
    loading: false,
    error: null,
    deviceStorageFiles: {},
    totalItems: 0,
    itemsPerPage: 0
  };

  /* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
  export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
      case DEVICES_STORAGE_FETCH_FILES_REQUEST:
        return { ...state, loading: true, deviceStorageFiles: {}, totalItems: 0, error: null, itemsPerPage: action.payload};
      case DEVICES_STORAGE_FETCH_FILES:
        if (action.payload.list !== undefined && action.payload.list[0] !== null) {
          return {
            ...state,
            loading: false,
            deviceStorageFiles: _.mapKeys(action.payload.list, "id"),
            totalItems: action.payload.totalItems,
            error: null
          };
        } else {
          return { ...state, loading: false, deviceStorageFiles: {}, totalItems: 0, error: action.payload };
        }
      case DEVICES_STORAGE_FETCH_FILES_ERROR:
        return { ...state, loading: false, deviceStorageFiles: {}, totalItems: 0, error: action.payload };
      default:
        return state;
    }
  };