import quercusApi from '../../apis/quercusApi';
import { handleError } from '../../helpers/handleError';
import {
    RELEASES_FETCH_FILES,
    RELEASES_FETCH_FILES_REQUEST,
    RELEASES_FETCH_FILES_ERROR
} from './types';

export const fetchFiles = ({ offset, limit }, releaseId, returnPending) => async (dispatch) => {
    dispatch({ type: RELEASES_FETCH_FILES_REQUEST });
    try {
        let query = {
            params: {
                size: limit,
                page: offset / limit,
                sort: 'modifiedDate,desc',
                returnPending: returnPending
            }
        };
        const response = await quercusApi.get(`/api/v1/releases/${releaseId}/files`, query);
        dispatch({ type: RELEASES_FETCH_FILES, payload: response.data });
    } catch (error) {
        dispatch({ type: RELEASES_FETCH_FILES_ERROR, payload: handleError(error) });
    }
};