import React from "react";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Grid, Input, Modal } from "semantic-ui-react";

const DialogLprType = ({ action, header, pHCode, pHLicense, pHLprCountries, pHProduct, trigger, onClickAction }) => {
    const [open, setOpen] = React.useState(false);
    const [lprType, setLprType] = React.useState({ code: '', license: '', lprCountries: '', product: '' });
    const { t } = useTranslation();

    const licenseOptions = [
        { key: 'basic', value: 'Basic', text: 'Basic' },
        { key: 'standard', value: 'Standard', text: 'Standard' }
      ];
    
    const lprCountriesOptions = [
        { key: 'na', value: 'NA', text: 'NA' },
        { key: 'nona', value: 'NoNA', text: 'NoNA' },
        { key: 'nonaLite', value: 'NoNA-Lite', text: 'NoNA-Lite' },
        { key: 'SPL', value: 'SPL', text: 'SPL' },
        { key: 'Worldwide', value: 'Worldwide', text: 'Worldwide' }
      ];

    const productOptions = [
      { key: 'lpr5', value: 'LPR5', text: 'SmartLPR5' },
      { key: 'lpr6', value: 'LPR6', text: 'SmartLPR6' }
    ];

    const onOpen = () => {
        setOpen(true);
        setLprType({ code: '', license: '', lprCountries: '', product: '' });
    };

    const onClick = () => {
        onClickAction(lprType);
        setOpen(false);
    };

    const handleCodeOnChange = (e, { value }) => {
        setLprType({ ...lprType, code: value });
    };

    const handleLicenseOnChange = (e, { value }) => {
        setLprType({ ...lprType, license: value });
    };

    const handleLprCountriesOnChange = (e, { value }) => {
        setLprType({ ...lprType, lprCountries: value });
    };

    const handleProductOnChange = (e, { value }) => {
            setLprType({ ...lprType, product: value });
        };

    const isAnyFieldEmpty = (action) => {
        if (action === 'edit') return false
        return lprType.code.length === 0
            || lprType.license.length === 0
            || lprType.lprCountries.length === 0
            || lprType.product.length === 0;
    };
    if (action !== 'edit') {
      pHCode= t(pHCode)
      pHLicense= t(pHLicense)
      pHLprCountries= t(pHLprCountries)
      pHProduct= t(pHProduct)
    }

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={onOpen}
            closeOnDimmerClick={false}
            dimmer='blurring'
            size='small'
            open={open}
            trigger={trigger}
            >
            <Modal.Header>{t(header)}</Modal.Header>
            <Modal.Content>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            {t('lprPs.types.actions.basic.code')}:
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <Input
                                placeholder={pHCode}
                                value={lprType.code}
                                onChange={handleCodeOnChange}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            {t('lprPs.types.actions.basic.license')}:
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <Dropdown
                                selection
                                options={licenseOptions}
                                value={lprType.license}
                                onChange={handleLicenseOnChange}
                                placeholder={pHLicense}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            {t('lprPs.types.actions.basic.lprCountries')}:
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <Dropdown
                                selection
                                options={lprCountriesOptions}
                                value={lprType.lprCountries}
                                onChange={handleLprCountriesOnChange}
                                placeholder={pHLprCountries}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            {t('lprPs.types.actions.basic.product')}:
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <Dropdown
                                selection
                                options={productOptions}
                                value={lprType.product}
                                onChange={handleProductOnChange} //NOSONAR
                                placeholder={pHProduct}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={() => setOpen(false)}>
                    {t('common.actions.cancel')}
                </Button>
                <Button onClick={onClick} primary disabled={isAnyFieldEmpty(action)}>
                    {t('common.actions.save')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

DialogLprType.propTypes = {
    trigger: PropTypes.element.isRequired,
    onClickAction: PropTypes.func.isRequired,
}

export default DialogLprType;