import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../../providers/alertsProvider";
import { useLoader } from "../../../providers/loaderProvider";
import { hasRole } from "../../../utils/userRolesUtils";
import { Button, Icon, Menu, Tab, Table } from "semantic-ui-react";
import DialogModal from "../../core/DialogModal";

import { fetchScApiVersion, getNextSerialNumber, incrementBatch } from '../../../actions/spotControl/scConfigAction';

const TabScConfig = () => {
  const scConfigData = useSelector(state => state.scConfig);
  const authData = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const { addError, addSuccess } = useAlert();
  const { showLoader, hideLoader } = useLoader();
  const { roles } = authData.user;
  const { t } = useTranslation();
  
  React.useEffect(() => {
    dispatch(fetchScApiVersion());
    dispatch(getNextSerialNumber());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (scConfigData.error){
      addError(scConfigData.error.error, scConfigData.error.message);
      hideLoader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scConfigData.error]);

  React.useEffect(() => {
    if (scConfigData.batch){
      addSuccess(t('sc2Ps.config.increment.success'), `${t('sc2Ps.config.increment.successDesc')} ${scConfigData.batch}`);
      hideLoader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scConfigData.batch]);

  const increment = () => {
    showLoader();
    dispatch(incrementBatch());
  };
    
  return (
    <Tab.Pane>
      <Menu secondary>
        <Menu.Menu position='right'>
            {hasRole(roles, 'sc2', 'editor') && 
              <Menu.Item>
                <DialogModal 
                      header='sc2Ps.config.increment.header' 
                      content='sc2Ps.config.increment.content'
                      trigger={<Button loading={scConfigData.loading} primary><Icon name='arrow alternate circle up' />{t('sc2Ps.config.increment.button')}</Button>}
                      onClickAction={increment}
                      yesLable='common.actions.confirm'
                      noLabel='common.actions.cancel'/>
              </Menu.Item>
            }
        </Menu.Menu>
      </Menu>

      <Table compact celled definition>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>{t('common.labels.value')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common.labels.description')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell collapsing>{t('sc2Ps.config.table.batch')}</Table.Cell>
            <Table.Cell>{scConfigData && scConfigData.configuration ? scConfigData.configuration.batch : ''}</Table.Cell>
            <Table.Cell>{t('sc2Ps.config.table.batchDesc')}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell collapsing>{t('sc2Ps.config.table.serialNumber')}</Table.Cell>
            <Table.Cell>{scConfigData && scConfigData.configuration ? scConfigData.configuration.serialNumber : ''}</Table.Cell>
            <Table.Cell>{t('sc2Ps.config.table.serialNumberDesc')}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell collapsing>{t('sc2Ps.config.table.apiVersion')}</Table.Cell>
            <Table.Cell>{scConfigData ? scConfigData.version : ''}</Table.Cell>
            <Table.Cell>{t('sc2Ps.config.table.apiVersionDesc')}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>      
    </Tab.Pane>
  );
};

export default TabScConfig;