import React from "react";
import { Tab } from "semantic-ui-react";

import TableUsers from "../tables/TableUsers";

const TabUsers = () => {
  return (
    <Tab.Pane>
        <TableUsers />
    </Tab.Pane>
  );
};

export default TabUsers;