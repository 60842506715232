import React from "react";
import { Tab } from "semantic-ui-react";

import TableDevicesStorageFile from "../tables/TableDeviceStorageFiles";

const TabDeviceStorageFiles = () => {
  return (
    <Tab.Pane>
      <TableDevicesStorageFile />
    </Tab.Pane>  );
};

export default TabDeviceStorageFiles;