import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";

import { useTable, TableProvider } from "../../../providers/tableProvider";
import { useAlert } from "../../../providers/alertsProvider";
import { useLoader } from "../../../providers/loaderProvider";
import TablePaginated from "../../core/TablePaginated";
import TableCellProfile from "../../core/TableCellProfile";
import TableCellActions from "../../core/TableCellActions";
import DialogModal from "../../core/DialogModal";
import DialogEditUser from "../dialog/DialogEditUser";
import { Button, Icon, Label, Table } from "semantic-ui-react";

import { fetchUsers, editUser, deleteUser } from "../../../actions/administration/usersAction";

function TableComposition () {
    let table = useTable();
    const users = useSelector(state => state.users);
    const dispatch = useDispatch();
    const { addError, addSuccess } = useAlert();
    const { showLoader, hideLoader } = useLoader();
    const { t } = useTranslation();

    const initialRender = React.useRef(false);
    
    React.useEffect(() => {
        loadUsers();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table.filters]);

    React.useEffect(() => {
      if (initialRender.current && users.deleteSuccess){
        addSuccess(t('common.labels.success'), t('administration.users.actions.deleteSuccess'));
        hideLoader();
      } else if (initialRender.current && users.editSuccess){
        addSuccess(t('common.labels.success'), t('administration.users.actions.edit.success'));
        hideLoader();
      } else if (initialRender.current && users.error){
        addError(users.error.error, users.error.message);
        hideLoader();
      } else if (!initialRender.current){
        initialRender.current = true;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users]);

    const loadUsers = () => {
      dispatch(fetchUsers(table.filters));
    }

    const onDeleteClick = (userId) => {
      showLoader(t('administration.users.actions.deleteLoader'));
      dispatch(deleteUser(userId));
    };

    const onEditClick = (user) => {
      showLoader(t('administration.users.actions.edit.loader'));
      dispatch(editUser(user));
    };

    const getActions = (user) => {
      const userIsDeleted = user.deletedDate && user.deletedDate !== null;
      return [
        { 
          key: 'edit',
          customElement: <DialogEditUser key='edit' trigger={<Button primary icon disabled={userIsDeleted}><Icon name='edit' /></Button>} onSave={onEditClick} user={user}/>
        },
        { 
          key: 'delete',
          customElement: <DialogModal key='delete' header='administration.users.actions.deleteModalHeader' content='administration.users.actions.deleteModalContent' trigger={<Button primary icon disabled={userIsDeleted}><Icon name='trash alternate' /></Button>} onClickAction={() => onDeleteClick(user.id)} />
        }
      ];
    }

    const showUserRoles = (roles, key) => {
      return (
        <Table.Cell key={key}>
          {roles 
            && roles.length > 0 
            && roles.map(role => {
              return (<Label key={role}>{role}</Label>)
            })}
          {(!roles || roles.length === 0) 
            && t('administration.users.table.noRoles')}
        </Table.Cell>
      );
    }

    const headerRow = [
      t('administration.users.table.user'), 
      t('administration.users.table.email'), 
      t('administration.users.table.lastConnection'),
      t('administration.users.table.roles'),
      t('common.labels.actions')];

    const renderBodyRow = (data, i) => ({
      key: `row-${i}`,
      cells: [
        { key: 'profile', as: TableCellProfile, profileUrl: data.imageUrl, profileName: data.name },
        data.email,
        data.lastConnection ? moment(data.lastConnection).format('DD-MM-YYYY HH:mm:ss') : '-',
        showUserRoles(data.roles, i),
        { key: 'actions', as: TableCellActions, actions: getActions(data), rowId: data.id }
      ],
      negative: data.deletedDate && data.deletedDate !== null
    });

    return (
      <div>
          <TablePaginated
            headerRow={headerRow}
            renderBodyRow={renderBodyRow}
            tableData={_.values(users.users)}
            loading={users.loading}
            totalItems={users.totalItems}
            setPagination={table.onPaginationChange}
          />
      </div>
    );
}

const TableUsers = () => <TableProvider><TableComposition /></TableProvider>

export default TableUsers;