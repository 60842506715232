import { 
    LPR_FETCH_VERSION,
    LPR_FETCH_VERSION_REQUEST,
    LPR_FETCH_VERSION_ERROR
} from '../../actions/lpr/types';
  
  const INTIAL_STATE = {
    loading: false,
    error: null,
    version: null
  };
  
  /* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
  export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
      case LPR_FETCH_VERSION_REQUEST:
        return { ...state, loading: true, version: null, error: null, batch: null };
      case LPR_FETCH_VERSION:
        return { ...state, loading: false, version: action.payload, error: null, batch: null };
      case LPR_FETCH_VERSION_ERROR:
        return { ...state, loading: false, version: null, error: action.payload, batch: null };
      
      default:
        return state;
    }
  };