import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Container, Image } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { hasRole } from "../utils/userRolesUtils";

import Page from "./Page";
import lpr from '../images/lpr.png';
import spot from '../images/spot.png';
import admin from '../images/administration.png';
import folder from '../images/folder.png';
import releases from '../images/releases.png';

const Dashboard = () => {
  const { t } = useTranslation();
  const authData = useSelector(state => state.auth);
  const { roles } = authData.user;
  
  const sections = [
    { key: 'Dashboard', content: t('breadcrumb.dashboard'), active: true }
  ]

  return (
    <Page sections={sections}>
      <Container>
        <Card.Group>
          {hasRole(roles, 'lpr') &&
            <Card as={Link} to={"/lpr"}>
              <Image src={lpr} wrapped ui={false} />
              <Card.Content>
                <Card.Header>{t('lprPs.title')}</Card.Header>
                <Card.Description>
                  {t('lprPs.description')}
                </Card.Description>
              </Card.Content>
            </Card>}
            {hasRole(roles, 'sc2') && 
              <Card as={Link} to={"/scIndoor"}>
                <Image src={spot} wrapped ui={false} />
                <Card.Content>
                  <Card.Header>{t('sc2Ps.title')}</Card.Header>
                  <Card.Description>
                    {t('sc2Ps.description')}
                  </Card.Description>
                </Card.Content>
              </Card>}
              {hasRole(roles, 'releases') && 
              <Card as={Link} to={"/releases"}>
                <Image src={releases} wrapped ui={false} />
                <Card.Content>
                  <Card.Header>{t('releases.title')}</Card.Header>
                  <Card.Description>
                    {t('releases.description')}
                  </Card.Description>
                </Card.Content>
              </Card>}
            {hasRole(roles, 'admin') && 
              <Card as={Link} to={"/administration"}>
                <Image src={admin} wrapped ui={false} />
                <Card.Content>
                  <Card.Header>{t('administration.title')}</Card.Header>
                  <Card.Description>
                    {t('administration.description')}
                  </Card.Description>
                </Card.Content>
              </Card>}
            {<Card as={Link} to={"/deviceStorage"}>
              <Image src={folder} wrapped ui={false} />
              <Card.Content>
                <Card.Header>{t('deviceStorage.title')}</Card.Header>
                <Card.Description>
                  {t('deviceStorage.description')}
                </Card.Description>
              </Card.Content>
            </Card>}
        </Card.Group>
      </Container>
    </Page>
  );
};

export default Dashboard;