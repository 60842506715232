import { 
  LPR5_FETCH_MAC_RANGES,
  LPR5_FETCH_MAC_RANGES_REQUEST,
  LPR5_FETCH_MAC_RANGES_ERROR,
  LPR5_FETCH_MACS_COUNT,
  LPR5_FETCH_MACS_COUNT_REQUEST,
  LPR5_FETCH_MACS_COUNT_ERROR,
  LPR5_CREATE_MAC_RANGE,
  LPR5_CREATE_MAC_RANGE_REQUEST,
  LPR5_CREATE_MAC_RANGE_ERROR
} from '../../actions/lpr/types';
  
  const INTIAL_STATE = {
    loading: false,
    error: null,
    macRanges: [],
    totalItems: 0,
    freeMacs: 0,
    createSuccessMsg: null
  };
  
  /* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
  export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
      case LPR5_FETCH_MAC_RANGES_REQUEST:
        return { ...state, loading: true, macRanges: [], totalItems: 0, createSuccessMsg: null, error: null};
      case LPR5_FETCH_MAC_RANGES:
        return { ...state, loading: false, macRanges: action.payload.list, totalItems: action.payload.pagination.totalItems, error: null };
      case LPR5_FETCH_MAC_RANGES_ERROR:
        return { ...state, loading: false, macRanges: [], totalItems: 0, error: action.payload };
      
      case LPR5_FETCH_MACS_COUNT_REQUEST:
        return { ...state, loading: true, freeMacs: 0, createSuccessMsg: null, error: null};
      case LPR5_FETCH_MACS_COUNT:
        return { ...state, loading: false, freeMacs: action.payload.freeMacs, error: null };
      case LPR5_FETCH_MACS_COUNT_ERROR:
        return { ...state, loading: false, freeMacs: 0, error: action.payload };

      case LPR5_CREATE_MAC_RANGE_REQUEST:
        return { ...state, loading: true, createSuccessMsg: null, error: null};
      case LPR5_CREATE_MAC_RANGE:
        return { ...state, loading: false, createSuccessMsg: action.payload.message, error: null };
      case LPR5_CREATE_MAC_RANGE_ERROR:
        return { ...state, loading: false, createSuccessMsg: null, error: action.payload };
      
      default:
        return state;
    }
  };