import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { useTable, TableProvider } from "../../../providers/tableProvider";
import { Label, Table } from "semantic-ui-react";
import TablePaginated from "../../core/TablePaginated";
import TableCellProfile from "../../core/TableCellProfile";

import { fetchUserActivities } from "../../../actions/administration/userActivitiesAction";

function TableComposition () {
    let table = useTable();
    const userActivities = useSelector(state => state.userActivities);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    let services = {
      "LPR5_PS": 'blue', 
      "SC2_PS": 'green',
      "RELEASE": 'yellow'
    };
    
    React.useEffect(() => {
        loadUserActivities();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table.filters]);

    const loadUserActivities = () => {
      dispatch(fetchUserActivities(table.filters));
    }

    const showService = (service, key) => {
      return (
        <Table.Cell key={key}>
          <Label key={service.toUpperCase()} color={services[service.toUpperCase()]}>{service.toUpperCase()}</Label>
        </Table.Cell>
      );
    }

    const headerRow = [
      t('administration.userActivityLog.table.date'),
      t('administration.userActivityLog.table.service'),
      t('administration.userActivityLog.table.operation'), 
      t('administration.userActivityLog.table.description'),
      t('administration.userActivityLog.table.objectId'),
      t('administration.userActivityLog.table.performedBy')];

    const renderBodyRow = ({ operation, description, service, date, userName, userImageUrl, objectId }, i) => ({
      key: `row-${i}`,
      cells: [
        date ? moment(date).format('DD-MM-YYYY HH:mm:ss') : '-',
        showService(service, i),
        operation,
        description,
        objectId ? objectId : t('common.labels.none'),
        { key: 'profile', as: TableCellProfile, profileUrl: userImageUrl, profileName: userName }
      ]
    });

    return (
      <div>
          <TablePaginated
            headerRow={headerRow}
            renderBodyRow={renderBodyRow}
            tableData={userActivities.list}
            loading={userActivities.loading}
            totalItems={userActivities.totalItems}
            setPagination={table.onPaginationChange}
          />
      </div>
    );
}

const TableUserActivities = () => <TableProvider><TableComposition /></TableProvider>

export default TableUserActivities;