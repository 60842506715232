import quercusApi from '../../apis/quercusApi';
import { handleError } from '../../helpers/handleError';
import { addFiltersToQueryUrl } from '../../utils/filterUtils';
import {
    RELEASES_FETCH_RELEASE,
    RELEASES_FETCH_RELEASE_REQUEST,
    RELEASES_FETCH_RELEASE_ERROR,
    RELEASES_FETCH_RELEASES,
    RELEASES_FETCH_RELEASES_REQUEST,
    RELEASES_FETCH_RELEASES_ERROR
} from './types';

export const fetchRelease = (releaseId) => async (dispatch) => {
    dispatch({ type: RELEASES_FETCH_RELEASE_REQUEST });
    try {
        const response = await quercusApi.get(`/api/v1/releases/${releaseId}`);

        dispatch({ type: RELEASES_FETCH_RELEASE, payload: response.data });
    } catch (error) {
        dispatch({ type: RELEASES_FETCH_RELEASE_ERROR, payload: handleError(error) });
    }
};

export const fetchReleases = ({ offset, limit, queries }, productionOnly) => async (dispatch) => {
    dispatch({ type: RELEASES_FETCH_RELEASES_REQUEST, payload: limit });
    try {
        const sort = "releaseDate,desc";

        let query = {
            params: {
                size: limit,
                page: offset / limit,
                sort: sort
            }
        };

        if (productionOnly) {
            queries = [...queries, { field: 'status', value: 'PRODUCTION' }]
        }

        query = addFiltersToQueryUrl(query, queries);
        const response = await quercusApi.get('/api/v1/releases', query);

        dispatch({ type: RELEASES_FETCH_RELEASES, payload: response.data });
    } catch (error) {
        dispatch({ type: RELEASES_FETCH_RELEASES_ERROR, payload: handleError(error) });
    }
};