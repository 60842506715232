import React from "react";
import PropTypes from 'prop-types';
import { Dimmer, Loader, Segment } from "semantic-ui-react";

import HeaderPage from "../components/HeaderPage";
import { useLoader } from "../providers/loaderProvider";

const Page = ({ children, sections }) => {
    const { loader } = useLoader();

    return (
        <Segment basic>
            <HeaderPage sections={sections} />
            <Dimmer active={loader.active} inverted>
                <Loader size='large'>{loader.message}</Loader>
            </Dimmer>
            {children}
        </Segment>
    );
};

Page.propTypes = {
    sections: PropTypes.array.isRequired
};

export default Page;