import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Segment, Tab } from "semantic-ui-react";

import Page from "./Page";
import TabLprProductions from "../components/lpr/tabs/TabLprProductions";
import TabLprQcs from "../components/lpr/tabs/TabLprQcs";
import TabLpr5Macs from "../components/lpr/tabs/TabLpr5Macs";
import TabLprConfig from "../components/lpr/tabs/TabLprConfig";
import TabLprTypes from "../components/lpr/tabs/TabLprTypes";

const LPR = () => {
  const { t } = useTranslation();

  const sections = [
    { key: 'Dashboard', content: t('breadcrumb.dashboard'), link: true, as: Link, to: '/'},
    { key: 'lpr', content: t('breadcrumb.lpr'), active: true }
  ];

  const getVisiblePanes = () => {
    let panes = [];

    panes.push({ menuItem: t('lprPs.productions.tabTitle'), render: () => <TabLprProductions /> });
    panes.push({ menuItem: t('lprPs.qcs.tabTitle'), render: () => <TabLprQcs /> });
    panes.push({ menuItem: t('lprPs.macRange.tabTitle'), render: () => <TabLpr5Macs /> });
    panes.push({ menuItem: t('lprPs.types.tabTitle'), render: () => <TabLprTypes /> });
    panes.push({ menuItem: t('lprPs.config.tabTitle'), render: () => <TabLprConfig /> });

    return panes;
  }
    
  return (
    <Page sections={sections}>
      <Segment basic>
        <Tab panes={getVisiblePanes()} />
      </Segment>
    </Page>
  );
};

export default LPR;