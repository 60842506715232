export const SC_FETCH_VERSION = 'SC_FETCH_VERSION';
export const SC_FETCH_VERSION_REQUEST = 'SC_FETCH_VERSION_REQUEST';
export const SC_FETCH_VERSION_ERROR = 'SC_FETCH_VERSION_ERROR';

export const SC_GET_SERIAL_NUMBER = 'SC_GET_SERIAL_NUMBER';
export const SC_GET_SERIAL_NUMBER_REQUEST = 'SC_GET_SERIAL_NUMBER_REQUEST';
export const SC_GET_SERIAL_NUMBER_ERROR = 'SC_GET_SERIAL_NUMBER_ERROR';

export const SC_INCREMENT_BATCH = 'SC_INCREMENT_BATCH';
export const SC_INCREMENT_BATCH_REQUEST = 'SC_INCREMENT_BATCH_REQUEST';
export const SC_INCREMENT_BATCH_ERROR = 'SC_INCREMENT_BATCH_ERROR';

export const SC_FETCH_PRODUCTIONS = 'SC_FETCH_PRODUCTIONS';
export const SC_FETCH_PRODUCTIONS_REQUEST = 'SC_FETCH_PRODUCTIONS_REQUEST';
export const SC_FETCH_PRODUCTIONS_ERROR = 'SC_FETCH_PRODUCTIONS_ERROR';

export const SC_FETCH_QCS = 'SC_FETCH_QCS';
export const SC_FETCH_QCS_REQUEST = 'SC_FETCH_QCS_REQUEST';
export const SC_FETCH_QCS_ERROR = 'SC_FETCH_QCS_ERROR';