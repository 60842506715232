import quercusApi from '../../apis/quercusApi';
import { handleError } from '../../helpers/handleError';
import {
    LPR_FETCH_VERSION,
    LPR_FETCH_VERSION_REQUEST,
    LPR_FETCH_VERSION_ERROR
} from './types';

export const fetchLprApiVersion = () => async (dispatch) => {
    dispatch({ type: LPR_FETCH_VERSION_REQUEST });
    try {
        const response = await quercusApi.get('/lprPS');
        dispatch({ type: LPR_FETCH_VERSION, payload: response.data.version });
    } catch (error) {
        dispatch({ type: LPR_FETCH_VERSION_ERROR, payload: handleError(error) });
    }
};