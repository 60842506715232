import { combineReducers } from 'redux';
import authReducer from './core/authReducer';
import usersReducer from './administration/usersReducer';
import userActivitiesReducer from './administration/userActivitiesReducer';
import scConfigReducer from './spotControl/scConfigReducer';
import scProductionsReducer from './spotControl/scProductionsReducer';
import scQcsReducer from './spotControl/scQcsReducer';
import lprProductionsReducer from './lpr/lprProductionsReducer';
import lprQcsReducer from './lpr/lprQcsReducer';
import lpr5MacsReducer from './lpr/lpr5MacsReducer';
import lprConfigReducer from './lpr/lprConfigReducer';
import lprTypesReducer from './lpr/lprTypesReducer';
import releasesReducer from './releases/releasesReducer';
import releasesFilesReducer from './releases/releasesFilesReducer';
import releasesProductsReducer from './releases/releasesProductsReducer';
import releasesPackagesReducer from './releases/releasesPackagesReducer';
import devicesStorageFilesReducer from './devicesStorage/devicesStorageFilesReducer'

export default combineReducers({
  auth: authReducer,
  users: usersReducer,
  userActivities: userActivitiesReducer,
  scConfig: scConfigReducer,
  scProductions: scProductionsReducer,
  scQcs: scQcsReducer,
  lpr5Productions: lprProductionsReducer,
  lpr5Qcs: lprQcsReducer,
  lpr5Macs: lpr5MacsReducer,
  lpr5Config: lprConfigReducer,
  lpr5Types: lprTypesReducer,
  releases: releasesReducer,
  releasesFiles: releasesFilesReducer,
  releasesProducts: releasesProductsReducer,
  releasesPackages: releasesPackagesReducer,
  deviceStorageFiles: devicesStorageFilesReducer
});