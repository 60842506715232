import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common_en from "./translations/common_en.json";

// the translations
const resources = {
    en: common_en
};

i18n  
.use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    //debug: true,
    defaultNS: "translations",
    initImmediate: false,
    preload: ["en"],
    fallbackLng: "en",
    lng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;