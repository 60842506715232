import { 
    LPR_FETCH_QCS,
    LPR_FETCH_QCS_REQUEST,
    LPR_FETCH_QCS_ERROR
} from '../../actions/lpr/types';
  
  const INTIAL_STATE = {
    loading: false,
    error: null,
    qcs: [],
    totalItems: 0
  };
  
  /* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
  export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
      case LPR_FETCH_QCS_REQUEST:
        return { ...state, loading: true, qcs: [], totalItems: 0, error: null};
      case LPR_FETCH_QCS:
        return { ...state, loading: false, qcs: action.payload.list, totalItems: action.payload.pagination.totalItems, error: null };
      case LPR_FETCH_QCS_ERROR:
        return { ...state, loading: false, qcs: [], totalItems: 0, error: action.payload };
      
      default:
        return state;
    }
  };