import React from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import FilterSelect from "../../core/filters/FilterSelect";

import { fetchPackages } from "../../../actions/releases/releasesPackagesAction";

const FilterPackages = ({ productId, onPackageSelect, loading }) => {
    const [selectedPackage, setSelectedPackage] = React.useState('');
    const [packages, setPackages] = React.useState([]);
    const packagesData = useSelector(state => state.releasesPackages);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    React.useEffect(() => {
        if (productId && productId.length > 0){
            dispatch(fetchPackages(productId, { offset: 0, limit: 100 }));
        } else {
            setPackages([]);
            setSelectedPackage('');
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId]);

    React.useEffect(() => {
        if (packagesData.packages && _.values(packagesData.packages).length > 0){
            setPackages(_.values(packagesData.packages).map(p => {
                return { key: p.id, text: p.description, value: p.id }
            }));
        } else {
            setPackages([]);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [packagesData.packages]);

    const handlePackageChanged = (value) => {
        setSelectedPackage(value);
        onPackageSelect('packageId', value);
      };

    return (
        <FilterSelect 
            selectedValue={selectedPackage}
            onValueChanged={handlePackageChanged}
            placeholder={t('releases.list.filters.packagePlaceholder')}
            options={packages}
            loading={loading || packagesData.loading}/>
    );
};

FilterPackages.propTypes = {
    productId: PropTypes.string.isRequired,
    onPackageSelect: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
}

export default FilterPackages;