import React from "react";
import { Icon, Input } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

const FilterDeviceId = ({ onDeviceIdSelect, loading }) => {
  const [selectedDevId, setSelectedDevId] = React.useState('');
  const { t } = useTranslation();

  const handleDevIdChanged = (e, { value }) => {
    if (value.length === 0){
      setSelectedDevId('');
      onDeviceIdSelect('deviceId', '');
    }
    setSelectedDevId(value);
    if (value.length >= 14){
      onDeviceIdSelect('deviceId', value);
    }
  };

  const handleDeleteClick = () => {
    setSelectedDevId('');
    onDeviceIdSelect('deviceId', '');
  };

  return (
    <Input
      icon={<Icon name='delete' link onClick={handleDeleteClick} disabled={selectedDevId.length === 0}/>}
      placeholder={t('deviceStorage.files.filters.deviceIdPlaceholder')}
      value={selectedDevId}
      onChange={handleDevIdChanged}
      loading={loading}/>
  );

};

FilterDeviceId.propTypes = {
  onDeviceIdSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}

export default FilterDeviceId;