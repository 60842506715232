import quercusApi from '../../apis/quercusApi';
import { handleError } from '../../helpers/handleError';
import { getSearchString, getOrder } from '../../utils/filterUtils';
import {
    LPR_FETCH_PRODUCTIONS,
    LPR_FETCH_PRODUCTIONS_REQUEST,
    LPR_FETCH_PRODUCTIONS_ERROR
} from './types';

export const fecthProductions = ({ offset, limit, queries }) => async (dispatch) => {
    dispatch({ type: LPR_FETCH_PRODUCTIONS_REQUEST });
    try {
        let query = {
            params: {
                limit: limit,
                offset: offset 
            }
        };
        if (queries.length > 0){
          query.params.searchString = getSearchString(queries);
          query.params.order = getOrder(queries);
        }

        const response = await quercusApi.get('/lprPS/lpr/productions', query);
        dispatch({ type: LPR_FETCH_PRODUCTIONS, payload: response.data });
    } catch (error) {
        dispatch({ type: LPR_FETCH_PRODUCTIONS_ERROR, payload: handleError(error) });
    }
};