export const LPR_FETCH_VERSION = 'LPR_FETCH_VERSION';
export const LPR_FETCH_VERSION_REQUEST = 'LPR_FETCH_VERSION_REQUEST';
export const LPR_FETCH_VERSION_ERROR = 'LPR_FETCH_VERSION_ERROR';

export const LPR_FETCH_PRODUCTIONS = 'LPR_FETCH_PRODUCTIONS';
export const LPR_FETCH_PRODUCTIONS_REQUEST = 'LPR_FETCH_PRODUCTIONS_REQUEST';
export const LPR_FETCH_PRODUCTIONS_ERROR = 'LPR_FETCH_PRODUCTIONS_ERROR';

export const LPR_FETCH_QCS = 'LPR_FETCH_QCS';
export const LPR_FETCH_QCS_REQUEST = 'LPR_FETCH_QCS_REQUEST';
export const LPR_FETCH_QCS_ERROR = 'LPR_FETCH_QCS_ERROR';

export const LPR5_FETCH_MAC_RANGES = 'LPR5_FETCH_MAC_RANGES';
export const LPR5_FETCH_MAC_RANGES_REQUEST = 'LPR5_FETCH_MAC_RANGES_REQUEST';
export const LPR5_FETCH_MAC_RANGES_ERROR = 'LPR5_FETCH_MAC_RANGES_ERROR';

export const LPR5_FETCH_MACS_COUNT = 'LPR5_FETCH_MACS_COUNT';
export const LPR5_FETCH_MACS_COUNT_REQUEST = 'LPR5_FETCH_MACS_COUNT_REQUEST';
export const LPR5_FETCH_MACS_COUNT_ERROR = 'LPR5_FETCH_MACS_COUNT_ERROR';

export const LPR5_CREATE_MAC_RANGE = 'LPR5_CREATE_MAC_RANGE';
export const LPR5_CREATE_MAC_RANGE_REQUEST = 'LPR5_CREATE_MAC_RANGE_REQUEST';
export const LPR5_CREATE_MAC_RANGE_ERROR = 'LPR5_CREATE_MAC_RANGE_ERROR';

export const LPR_FETCH_TYPES = 'LPR_FETCH_TYPES';
export const LPR_FETCH_TYPES_REQUEST = 'LPR_FETCH_TYPES_REQUEST';
export const LPR_FETCH_TYPES_ERROR = 'LPR_FETCH_TYPES_ERROR';

export const LPR_ADD_TYPE = 'LPR_ADD_TYPE';
export const LPR_ADD_TYPE_REQUEST = 'LPR_ADD_TYPE_REQUEST';
export const LPR_ADD_TYPE_ERROR = 'LPR_ADD_TYPE_ERROR';