import quercusApi from '../../apis/quercusApi';
import { handleError } from '../../helpers/handleError';
import {
    RELEASES_FETCH_PACKAGES,
    RELEASES_FETCH_PACKAGES_REQUEST,
    RELEASES_FETCH_PACKAGES_ERROR,
    RELEASES_CREATE_PACKAGE,
    RELEASES_CREATE_PACKAGE_REQUEST,
    RELEASES_CREATE_PACKAGE_ERROR
} from './types';

export const fetchPackages = (productId, { offset, limit }) => async (dispatch) => {
    dispatch({ type: RELEASES_FETCH_PACKAGES_REQUEST, payload: limit });
    try {
        let query = {
            params: {
                size: limit,
                page: offset/limit 
            }
        };

        const response = await quercusApi.get(`/api/v1/products/${productId}/packages`, query);
        dispatch({ type: RELEASES_FETCH_PACKAGES, payload: response.data });
    } catch (error) {
        dispatch({ type: RELEASES_FETCH_PACKAGES_ERROR, payload: handleError(error) });
    }
};

export const createPackage = (productId, packageForm) => async (dispatch) => {
    dispatch({ type: RELEASES_CREATE_PACKAGE_REQUEST });
    try {
        const response = await quercusApi.post(`/api/v1/products/${productId}/packages`, packageForm);
        dispatch({ type: RELEASES_CREATE_PACKAGE, payload: response.data });
    } catch (error) {
        dispatch({ type: RELEASES_CREATE_PACKAGE_ERROR, payload: handleError(error) });
    }
};