import history from "../../history";
import quercusApi from '../../apis/quercusApi';
import { handleError } from '../../helpers/handleError';
import { SIGN_IN, SIGN_IN_ERROR, SIGN_IN_REQUEST, SIGN_OUT } from "./types";
import jwt from "jsonwebtoken";

export const signInMethod = googleResponse => async (dispatch) => {
    dispatch({type: SIGN_IN_REQUEST});
    try {
        sessionStorage.setItem("token", googleResponse.credential);
        const decodedJwt = jwt.decode(googleResponse.credential);
        // noinspection JSDeprecatedSymbols
        const response = await quercusApi.patch(`/api/users/${decodedJwt.sub}/register`,
            {
                email: decodedJwt.email,
                name: decodedJwt.name,
                imageUrl: decodedJwt.picture,
            });
        dispatch({type: SIGN_IN, payload: response.data});
        history.push("/");
    } catch (error) {
        dispatch({type: SIGN_IN_ERROR, payload: handleError(error)});
    }
};

export const signOutMethod = () => {
    sessionStorage.clear();
    history.push("/login");
    return {
        type: SIGN_OUT
    };
};