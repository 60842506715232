import React from "react";
import { Icon, Input } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

const FilterFileId = ({ onFileIdSelect, loading }) => {
  const [selectedFileId, setSelectedFileId] = React.useState('');
  const { t } = useTranslation();

  const handleFileIdChanged = (e, { value }) => {
    if (value.length === 0){
      setSelectedFileId('');
      onFileIdSelect('id', '');
    }
    setSelectedFileId(value);
    if (value.length >= 20){
       onFileIdSelect('id', value);
    }
  };

  const handleDeleteClick = () => {
    setSelectedFileId('');
    onFileIdSelect('id', '');
  };

  return (
    <Input
      icon={<Icon name='delete' link onClick={handleDeleteClick} disabled={selectedFileId.length === 0}/>}
      placeholder={t('deviceStorage.files.filters.fileIdPlaceholder')}
      value={selectedFileId}
      onChange={handleFileIdChanged}
      loading={loading}/>
  );
};

FilterFileId.propTypes = {
  onFileIdSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}

export default FilterFileId;