import React from "react";
import { Button, Dropdown, Input } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import PropTypes from 'prop-types';
import _ from "lodash";
import '../../css/Filter.css'

const Filter = ({ filters, onAddFilter, onClearFilters, loading }) => {
    const [selectedFilter, setSelectedFilter] = React.useState({ value: null, field: null, type: null, options: [], placeholder: null });
    const [inputFilter, setInputFilter] = React.useState('');
    const [order, setOrder] = React.useState('desc')
    const [dateInputFilter, setDateInputFilter] = React.useState({ value: null, operation: null });
    const [filterDisabled, setFilterDisabled] = React.useState(true);
    const { t } = useTranslation();

    const handleFilterChange = (e, { value }) => {
        const selected = _.find(filters, ['value', value]);
        setSelectedFilter({ 
            value: value,
            field: selected.field, 
            type: selected.type, 
            options: selected.options ? selected.options : [],
            placeholder: selected.placeholder ? selected.placeholder : null
        });
        setInputFilter('');
        setDateInputFilter({ value: null, type: null });
        setOrder('desc');
        setFilterDisabled(true);
    };

    const handleOrderChange = (e, { value }) => {
        setOrder(value);
    }

    const handleFilterClick = () => {
        if (selectedFilter.type === 'input' || selectedFilter.type === 'select' ){
            onAddFilter({
                field: selectedFilter.field,
                value: inputFilter,
                order: order
            });
        } else {
            onAddFilter({
                field: selectedFilter.field,
                value: dateInputFilter.value,
                operation: dateInputFilter.operation,
                order: order
            });
        }
    };
    //(currentOptions) => currentOptions.filter((currentOption) => !selected.includes(currentOption))

    const handleClearClick = () => {
        setSelectedFilter({ value: null, type: null });
        setInputFilter('');
        setFilterDisabled(true);
        if (filterDisabled)
        setOrder('desc');
        onClearFilters();
    };

    const handleInputOnChange = (e, { value }) => {
        setInputFilter(value);
        setFilterDisabled(!value || value.length === 0);
    };

    const handleSelectOnChange = (e, { value }) => {
        setInputFilter(value);
        setFilterDisabled(false);
    };

    const handleDateBeforeChange = (e, { value }) => {
        if (value) {
            setDateInputFilter({ value: value, operation: 'before' });
            setFilterDisabled(false);
        } else {
            setDateInputFilter({ value: null, operation: null });
            setFilterDisabled(true);
        }
        
    };

    const handleDateAfterChange = (e, { value }) => {
        if (value) {
            setDateInputFilter({ value: value, operation: 'after' });
            setFilterDisabled(false);
        } else {
            setDateInputFilter({ value: null, operation: null });
            setFilterDisabled(true);
        }
        
    };

    const handleDateBetweenChange = (e, { value }) => {
        if (value) {
            setDateInputFilter({ value: value, operation: 'dateBetween' });
            setFilterDisabled(false);
        } else {
            setDateInputFilter({ value: null, operation: null });
            setFilterDisabled(true);
        }

    };

    return (
        <div>
            <Dropdown
                selection
                placeholder={t('common.labels.selectFilter')}
                options={filters} 
                value={selectedFilter.value}
                onChange={handleFilterChange}/>

            {selectedFilter.type && selectedFilter.type === 'input' && 
                <Input
                    placeholder={selectedFilter.placeholder ? selectedFilter.placeholder : t('common.labels.insertValue')}
                    value={inputFilter}
                    onChange={handleInputOnChange}
                    style={{ marginLeft: '0.5em' }}/>
            }
            {selectedFilter.type && selectedFilter.type === 'select' && 
                <Dropdown
                    selection
                    value={inputFilter}
                    options={selectedFilter.options}
                    onChange={handleSelectOnChange}
                    placeholder={selectedFilter.placeholder ? selectedFilter.placeholder : t('common.labels.selectOne')}
                    style={{ marginLeft: '0.5em' }}/>
            }
            {selectedFilter.type && selectedFilter.type === 'dateBefore' && 
                <SemanticDatepicker onChange={handleDateBeforeChange}
                    value={dateInputFilter.value}
                    format='DD-MM-YYYY'
                    className='datePickerInput'
                    locale={t('common.calendar.locale')}/>
            }
            {selectedFilter.type && selectedFilter.type === 'dateAfter' && 
                <SemanticDatepicker onChange={handleDateAfterChange}
                    value={dateInputFilter.value}
                    format='DD-MM-YYYY'
                    className='datePickerInput'
                    locale={t('common.calendar.locale')}/>
            }
            {selectedFilter.type && selectedFilter.type === 'dateBetween' &&
                <SemanticDatepicker onChange={handleDateBetweenChange}
                    value={dateInputFilter.value}
                    format='DD-MM-YYYY'
                    className='datePickerInput'
                    locale={t('common.calendar.locale')}
                    type="range"/>
            }
            <Dropdown
              selection
              placeholder={t('common.labels.descending')}
              defaultValue={{ key: 1, text: t('common.labels.descending'), value: 'desc' } || 'Select'}
              options={[{ key: 1, text: t('common.labels.ascending'), value: 'asc' }, { key: 2, text: t('common.labels.descending'), value: 'desc' }]}
              onChange={handleOrderChange}
              style={{ marginLeft: '0.5em' }}/>

            <Button 
                icon='search' content={t('common.labels.filter')}
                primary 
                loading={loading}
                onClick={handleFilterClick}
                disabled={filterDisabled}
                style={{ marginLeft: '0.5em' }}/>
            <Button 
                icon='delete' content={t('common.actions.clear')}
                onClick={handleClearClick} 
                loading={loading}
                style={{ marginLeft: '0.5em' }}/>
        </div>
        
    );
};

Filter.propTypes = {
    filters: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
          ]).isRequired,
        text: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        field: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['input', 'select', 'dateBefore', 'dateAfter', 'dateBetween']).isRequired,
        placeholder: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string
              ]).isRequired,
            text: PropTypes.string.isRequired,
            value: PropTypes.any.isRequired
        })),
      })).isRequired,
    onAddFilter: PropTypes.func.isRequired,
    onClearFilters: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
}

export default Filter;