import './App.css';
import { Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

import history from './history';

import HeaderMenu from './components/HeaderMenu';

import { AlertProvider } from './providers/alertsProvider';
import { LoaderProvider } from './providers/loaderProvider';

import Login from './pages/Login';
import UnauthorizedAccess from './pages/error/UnauthorizedAccess';
import ResourceNotFound from './pages/error/ResourceNotFound';
import Dashboard from './pages/Dashboard';
import SpotControlIndoor from './pages/SpotControlIndoor';
import LPR from './pages/LPR';
import Administration from './pages/Administration';
import ReleasesMenu from './pages/releases/ReleasesMenu';
import deviceStorage from './pages/DeviceStorage'
import ReleaseDetails from './pages/releases/ReleaseDetails';
import { useEffect, useState } from "react";
import { signOutMethod } from "./actions/core/authAction";
import { useDispatch } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import exitImage from './images/exit-icon.png';
import { Button, Header, Image, Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { tokenIsExpired } from "./utils/tokenUtils";

function App() {

    const inactiveTimeout = 1000 * 1800; /* 30min for inactive timeout and pop-out the modal */
    const promptTimeout = 1000 * 10; /* 10s count down for the session closing */

    const [open, setOpen] = useState(false); /* Modal open state */
    const [remaining, setRemaining] = useState(0); /* Time before idle */

    /**
     * onPrompt will be called after the inactiveTimeout value is reached. In this case {inactiveTimeout} value.
     */
    const onPrompt = () => {
        if (sessionStorage.getItem("token")) {
            setOpen(true);
            setRemaining(promptTimeout);
        }
    }

    /**
     * onIdle will be called after the promptTimeout is reached. In this case {promptTimeout}.
     */
    const onIdle = () => {
        setOpen(false);
        setRemaining(0);
        if (sessionStorage.getItem("token")) {
            dispatch(signOutMethod());
        }
    }

    /**
     * onActive will only be called if `reset()` is called while `isPrompted()` is true.
     * Seems that is no refresh concept in new gsi, check GoogleLoginProps in lib.
     * Migration reference:
     * https://developers.google.com/identity/oauth2/web/guides/migration-to-gis#the_new_way
     * So, if the user still wants to be active, but the token is expired,
     * then the session will still be closed.
     */
    const onActive = () => {
        setOpen(false);
        setRemaining(0);
        if (tokenIsExpired()) {
            dispatch(signOutMethod());
        }
    }

    const {getRemainingTime, isPrompted, activate} = useIdleTimer({
        timeout: inactiveTimeout,
        promptTimeout,
        crossTab: false,
        onPrompt,
        onIdle,
        onActive
    })

    const handleStillHere = () => {
        setOpen(false);
        activate();
    }

    const dispatch = useDispatch();
    const {t} = useTranslation();

    useEffect(() => {
        const interval = setInterval(() => {
            if (isPrompted()) {
                setRemaining(Math.ceil(getRemainingTime() / 1000));
            }
        }, 1000)
        return () => {
            clearInterval(interval);
        }
        // eslint-disable-next-line
    }, [getRemainingTime, isPrompted])

    return (
        <Router history={history}>
            <AlertProvider>
                <LoaderProvider>
                    <HeaderMenu/>

                    <Modal onClose={onIdle} onOpen={() => setOpen(true)} open={open}>
                        <Modal.Header>{t('logout.sessionExpire')}</Modal.Header>
                        <Modal.Content image>
                            <Image size='small'
                                   src={exitImage} wrapped/>
                            <Modal.Description>
                                <Header>{t('logout.logoutMessage')}</Header>
                                <p>{t('logout.remainingTime', {remaining: remaining})}</p>
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                content={t('logout.logout')}
                                onClick={onIdle}
                                icon='sign-out'
                                negative>
                            </Button>
                            <Button
                                content={t('logout.keepSession')}
                                labelPosition='right'
                                icon='sign-in'
                                onClick={handleStillHere}
                                positive
                            />
                        </Modal.Actions>
                    </Modal>

                    <Switch>
                        <Route path="/login" exact component={Login}/>
                        <PrivateRoute path="/" exact component={Dashboard}/>
                        <PrivateRoute path="/unauthorizedAccess" exact component={UnauthorizedAccess}/>
                        <PrivateRoute path="/resourceNotFound" exact component={ResourceNotFound}/>
                        <PrivateRoute path="/scIndoor" exact component={SpotControlIndoor}/>
                        <PrivateRoute path="/lpr" exact component={LPR}/>
                        <PrivateRoute path="/administration" exact component={Administration}/>
                        <PrivateRoute path="/releases" exact component={ReleasesMenu}/>
                        <PrivateRoute path="/releases/:releaseId" exact component={ReleaseDetails}/>
                        <PrivateRoute path="/deviceStorage" exact component={deviceStorage}/>
                    </Switch>
                </LoaderProvider>
            </AlertProvider>
        </Router>
    );
}

export default App;
