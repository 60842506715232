import { 
    SC_FETCH_PRODUCTIONS,
    SC_FETCH_PRODUCTIONS_REQUEST,
    SC_FETCH_PRODUCTIONS_ERROR
} from '../../actions/spotControl/types';
  
  const INTIAL_STATE = {
    loading: false,
    error: null,
    productions: [],
    totalItems: 0
  };
  
  /* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
  export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
      case SC_FETCH_PRODUCTIONS_REQUEST:
        return { ...state, loading: true, productions: [], totalItems: 0, error: null};
      case SC_FETCH_PRODUCTIONS:
        return { ...state, loading: false, productions: action.payload.list, totalItems: action.payload.pagination.totalItems, error: null };
      case SC_FETCH_PRODUCTIONS_ERROR:
        return { ...state, loading: false, productions: [], totalItems: 0, error: action.payload };
      
      default:
        return state;
    }
  };