import React from "react";
import { Tab } from "semantic-ui-react";

import TableUserActivities from "../tables/TableUserActivities";

const TabUserActivityLog = () => {
  return (
    <Tab.Pane>
      <TableUserActivities />
    </Tab.Pane>
  );
};

export default TabUserActivityLog;