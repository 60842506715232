import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import FilterSelect from "../../core/filters/FilterSelect";

const FilterReleaseStatus = ({ onStatusSelect, loading }) => {
    const [selectedStatus, setSelectedStatus] = React.useState('');
    const { t } = useTranslation();

    const handleStatusChanged = (value) => {
        setSelectedStatus(value);
        onStatusSelect('status', value);
    };

    return (
        <FilterSelect
            selectedValue={selectedStatus} 
            onValueChanged={handleStatusChanged}
            placeholder={t('releases.list.filters.statusPlaceholder')}
            options={[
                { key: 1, text: 'QA', value: 'QA' }, 
                { key: 2, text: 'STAGE', value: 'STAGE' }, 
                { key: 3, text: 'PRODUCTION', value: 'PRODUCTION' }]}
            loading={loading}/>
    );
};

FilterReleaseStatus.propTypes = {
    onStatusSelect: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
}

export default FilterReleaseStatus;