import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Button, Icon, Menu } from "semantic-ui-react";

import { useTable, TableProvider } from "../../../providers/tableProvider";
import { useAlert } from "../../../providers/alertsProvider";
import { useLoader } from "../../../providers/loaderProvider";
import { hasRole } from "../../../utils/userRolesUtils";
import TablePaginated from "../../core/TablePaginated";
import TableCellActions from "../../core/TableCellActions";
import DialogProduct from "../dialog/DialogProduct";
import DialogPackages from "../dialog/DialogPackages";

import { fetchProducts, createProduct } from "../../../actions/releases/releasesProductsAction";

function TableComposition () {
    let table = useTable();
    const products = useSelector(state => state.releasesProducts);
    const authData = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const { addError, addSuccess } = useAlert();
    const { showLoader, hideLoader } = useLoader();
    const { roles } = authData.user;
    const { t } = useTranslation();

    const initialRender = React.useRef(false);
    
    React.useEffect(() => {
      loadProducts();
       //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table.filters]);

    React.useEffect(() => {
      if (initialRender.current && products.addSuccess){
        addSuccess(t('common.labels.success'), t('releases.products.actions.create.success'));
        hideLoader();
      } else if (initialRender.current && products.error){
          addError(products.error.error, products.error.message);
          hideLoader();
      } else if (!initialRender.current){
        initialRender.current = true;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products]);

    const loadProducts = () => {
      dispatch(fetchProducts(table.filters));
    }

    const createProductAction = (product) => {
      showLoader();
      dispatch(createProduct(product));
    };

    const getActions = (productId) => {
      return [
        { 
          key: 'packages',
          customElement: <DialogPackages key='packages' trigger={<Button primary icon><Icon name='box' /></Button>} productId={productId}/>
        }
      ];
    }

    const headerRow = [
      t('releases.products.table.id'), 
      t('releases.products.table.description'),
      t('common.labels.actions')
    ];

    const renderBodyRow = ({ id, description }, i) => ({
      key: `row-${i}`,
      cells: [
        { key: 'id', content: id },
        { key: 'description', content: description },
        { key: 'actions', as: TableCellActions, actions: getActions(id), rowId: id } 
      ]
    });

    return (
      <div>
          <Menu secondary>
            <Menu.Menu position='right'>
              {hasRole(roles, 'releases', 'admin') && 
                <Menu.Item>
                  <DialogProduct 
                    trigger={<Button primary loading={products.loading}><Icon name='plus circle' />{t('releases.products.menu.createProduct')}</Button>}
                    onClickAction={createProductAction}/>
                </Menu.Item>
              }
            </Menu.Menu>
          </Menu>

          <TablePaginated
            headerRow={headerRow}
            renderBodyRow={renderBodyRow}
            tableData={_.values(products.products)}
            loading={products.loading}
            totalItems={products.totalItems}
            setPagination={table.onPaginationChange}
          />
      </div>
    );
}

const TableProducts = () => <TableProvider><TableComposition /></TableProvider>

export default TableProducts;