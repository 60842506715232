import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Button, Icon, Label, Loader, Menu, Message } from "semantic-ui-react";

import { useTable, TableProvider } from "../../../providers/tableProvider";
import { useAlert } from "../../../providers/alertsProvider";
import { useLoader } from "../../../providers/loaderProvider";
import { useTranslation } from "react-i18next";
import { hasRole } from "../../../utils/userRolesUtils";
import TablePaginated from "../../core/TablePaginated";
import DialogMacRange from "../dialog/DialogMacRange";

import { fetchMacRanges, fetchMacsCount, createMacRange } from "../../../actions/lpr/lpr5MacsAction";

function TableComposition () {
    let table = useTable();
    const lpr5Macs = useSelector(state => state.lpr5Macs);
    const authData = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const { addError, addSuccess } = useAlert();
    const { showLoader, hideLoader } = useLoader();
    const { roles } = authData.user;
    const { t } = useTranslation();
    
    React.useEffect(() => {
      dispatch(fetchMacsCount());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lpr5Macs.macRanges]);

    React.useEffect(() => {
      loadMacRanges();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table.filters]);

    React.useEffect(() => {
      if (lpr5Macs.error){
        addError(lpr5Macs.error.error, lpr5Macs.error.message);
        hideLoader();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lpr5Macs.error]);

    React.useEffect(() => {
      if (lpr5Macs.createSuccessMsg){
        addSuccess(t('common.labels.success'), lpr5Macs.createSuccessMsg);
        hideLoader();
        loadMacRanges();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lpr5Macs.createSuccessMsg]);

    const loadMacRanges = () => {
      dispatch(fetchMacRanges(table.filters));
    }

    const createMacRangeAction = (macRange) => {
      showLoader();
      dispatch(createMacRange(macRange));
    };

    const headerRow = [
      t('lprPs.macRange.table.minAddress'),
      t('lprPs.macRange.table.maxAddress'),
      t('lprPs.macRange.table.creationDate'),
      t('lprPs.macRange.table.firstFreeAddress'),
      t('lprPs.macRange.table.freeAddress')];

    const renderBodyRow = ({ minAddress, maxAddress, createAt, firstFreeAddress, hasFreeAddress }, i) => ({
      key: `row-${i}`,
      cells: [
        minAddress ? { key: 'minAddress', content: minAddress } : '-',
        maxAddress ? { key: 'maxAddress', content: maxAddress } : '-',
        createAt ? moment(createAt, "YYYY/MM/DD hh:mm:ss").format('DD-MM-YYYY HH:mm:ss') : '-',
        firstFreeAddress ? { key: 'firstFreeAddress', content: firstFreeAddress } : '-',
        hasFreeAddress ? t('common.actions.yes') : t('common.actions.no')
      ],
    });

    const freeMacsLabelColor = () => {
      if (lpr5Macs.loading) return 'grey';
      if (!lpr5Macs.freeMacs || lpr5Macs.freeMacs <= 0) return 'red';
      return 'green';
    }

    const showMACRunOutMessage = () => {
        return !lpr5Macs.loading && (!lpr5Macs.freeMacs || lpr5Macs.freeMacs <= 0)
    }

    return (
      <div>
          <Menu secondary>
            <Menu.Menu position='right'>
              <Menu.Item>
                  { showMACRunOutMessage() &&
                      <Message size='large' negative>
                          <Message.Header>{t('lprPs.macRange.menu.macRunOutHeader')}</Message.Header>
                          <p>{t('lprPs.macRange.menu.macRunOutMessage')}</p>
                      </Message>
                  }
                <Label size='large' color={freeMacsLabelColor()}>
                  {t('lprPs.macRange.menu.freeMacs')}
                  <Label.Detail>
                    {lpr5Macs.loading ? <Loader active inline inverted size='tiny'/> : lpr5Macs.freeMacs}
                  </Label.Detail>
                </Label>
              </Menu.Item>
              {hasRole(roles, 'lpr', 'editor') && 
                <Menu.Item>
                  <DialogMacRange 
                    trigger={<Button primary loading={lpr5Macs.loading}><Icon name='plus circle' />{t('lprPs.macRange.menu.createMacRange')}</Button>}
                    onClickAction={createMacRangeAction}/>
                </Menu.Item>
              }
            </Menu.Menu>
          </Menu>

          <TablePaginated
            headerRow={headerRow}
            renderBodyRow={renderBodyRow}
            tableData={lpr5Macs.macRanges}
            loading={lpr5Macs.loading}
            totalItems={lpr5Macs.totalItems}
            setPagination={table.onPaginationChange}
          />
      </div>
    );
}

const TableLpr5Macs = () => <TableProvider><TableComposition /></TableProvider>

export default TableLpr5Macs;