import quercusApi from '../../apis/quercusApi';
import { handleError } from '../../helpers/handleError';
import { addFiltersToQueryUrl } from '../../utils/filterUtils';
import {
    DEVICES_STORAGE_FETCH_FILES,
    DEVICES_STORAGE_FETCH_FILES_REQUEST,
    DEVICES_STORAGE_FETCH_FILES_ERROR
} from './types';

export const fetchFiles = ({ offset, limit, queries }) => async (dispatch) => {
    dispatch({ type: DEVICES_STORAGE_FETCH_FILES_REQUEST, payload: limit});
    try {
        let query = {
            params: {
                size: limit,
                page: offset / limit,
            }
        };

      query = addFiltersToQueryUrl(query, queries);
      const response = await quercusApi.get('/api/v1/devices/file', query);
      dispatch({ type: DEVICES_STORAGE_FETCH_FILES, payload: response.data });
    } catch (error) {
        dispatch({ type: DEVICES_STORAGE_FETCH_FILES_ERROR, payload: handleError(error) });
    }
};