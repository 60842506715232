import React from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import FilterSelect from "../../core/filters/FilterSelect";

import { fetchProducts } from "../../../actions/releases/releasesProductsAction";

const FilterProducts = ({ onProductSelect, loading }) => {
    const [selectedProduct, setSelectedProduct] = React.useState('');
    const [products, setProducts] = React.useState([]);
    const productData = useSelector(state => state.releasesProducts);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    React.useEffect(() => {
        dispatch(fetchProducts({ offset: 0, limit: 100 }));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (productData.products && _.values(productData.products).length > 0){
            setProducts(_.values(productData.products).map(p => {
            return { key: p.id, text: p.description, value: p.id }
            }));
        } else {
            setProducts([]);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productData.products]);

    const handleProductChanged = (value) => {
        setSelectedProduct(value);
        onProductSelect('productId', value);
      };

    return (
        <FilterSelect
            selectedValue={selectedProduct} 
            onValueChanged={handleProductChanged}
            placeholder={t('releases.list.filters.productPlaceholder')}
            options={products}
            loading={loading || productData.loading}/>
    );
};

FilterProducts.propTypes = {
    onProductSelect: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
}

export default FilterProducts;