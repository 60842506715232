import _ from "lodash";
import {
    RELEASES_FETCH_PRODUCTS,
    RELEASES_FETCH_PRODUCTS_REQUEST,
    RELEASES_FETCH_PRODUCTS_ERROR,
    RELEASES_CREATE_PRODUCT,
    RELEASES_CREATE_PRODUCT_REQUEST,
    RELEASES_CREATE_PRODUCT_ERROR
} from '../../actions/releases/types';
  
  const INTIAL_STATE = {
    loading: false,
    error: null,
    products: {},
    totalItems: 0,
    itemsPerPage: 0,
    addSuccess: false
  };
  
  /* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
  export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
      case RELEASES_FETCH_PRODUCTS_REQUEST:
        return { ...state, loading: true, products: {}, totalItems: 0, error: null, itemsPerPage: action.payload, addSuccess: false };
      case RELEASES_FETCH_PRODUCTS:
        return { ...state, loading: false, products: _.mapKeys(action.payload.list, "id"), totalItems: action.payload.totalItems, error: null };
      case RELEASES_FETCH_PRODUCTS_ERROR:
        return { ...state, loading: false, products: {}, totalItems: 0, error: action.payload };
      
      case RELEASES_CREATE_PRODUCT_REQUEST:
        return { ...state, loading: true, error: null, addSuccess: false };
      case RELEASES_CREATE_PRODUCT:
        if (_.values(state.products).length < state.itemsPerPage){
          return { ...state, loading: false, products: { ...state.products, [action.payload.id]: action.payload }, totalItems: state.totalItems+1, error: null, addSuccess: true };
        }
        return { ...state, loading: false, totalItems: state.totalItems+1, error: null, addSuccess: true };
      case RELEASES_CREATE_PRODUCT_ERROR:
        return { ...state, loading: false, error: action.payload };
      
      default:
        return state;
    }
  };