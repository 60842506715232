import React from "react";
import { Tab } from "semantic-ui-react";

import TableLprQcs from "../tables/TableLprQcs";

const TabLprQcs = () => {
  return (
    <Tab.Pane>
        <TableLprQcs />
    </Tab.Pane>
  );
};

export default TabLprQcs;