import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";

import { Label, Menu, Table } from "semantic-ui-react";
import { useTable, TableProvider } from "../../../providers/tableProvider";
import { useAlert } from "../../../providers/alertsProvider";
import { useLoader } from "../../../providers/loaderProvider";
import TablePaginated from "../../core/TablePaginated";
import TableCellActions from "../../core/TableCellActions";
import { getDownloadFileFromDeviceStorage } from "../../../helpers/handleDownloadFile";

import FilterFileId from "../filters/FilterFileId";
import FilterDeviceId from "../filters/FilterDeviceId";
import FilterDeviceType from "../filters/FilterDeviceType";
import FilterDeviceModel from "../filters/FilterDeviceModel";
import FilterFileTag from "../filters/FilterFileTag";


import _ from "lodash";

import { fetchFiles } from "../../../actions/devicesStorage/devicesStorageFilesAction";
import moment from "moment/moment";

function TableComposition () {
  let table = useTable();
  const dsData = useSelector(state => state.deviceStorageFiles);
  const dispatch = useDispatch();
  const { addError } = useAlert();
  const { showLoader, hideLoader } = useLoader();
  const { t } = useTranslation();

  React.useEffect(() => {
    loadFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.filters]);

  const loadFiles = () => {
    dispatch(fetchFiles(table.filters));
  }

  React.useEffect(() => {
    if (dsData.error){
      addError(dsData.error.error, dsData.error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dsData.error]);

  const handleFilterChanged = (field, value) => {
    if (!value || value.length === 0){
      table.onDeleteFilter(field);
    } else {
      table.onAddFilter({
          field: field,
          value: value
      });
    }
  };

  const showStatus = (status, key) => {
    const statusList = {
      "UPLOADED": 'green',
      "PENDING": 'red'
    };
    return (
      <Table.Cell key={key}>
        <Label key={status.toUpperCase()} color={statusList[status.toUpperCase()]}>{status.toUpperCase()}</Label>
      </Table.Cell>
    );
  }

  const onDownloadClick = (fileId) => {
    const url = `/api/v1/devices/file/${fileId}`;
    showLoader(t('deviceStorage.files.actions.downloadFileLoader'));
    getDownloadFileFromDeviceStorage(url)
      .then(response => {
        saveAs(response.data, `file_device_storage_${fileId}`);
        hideLoader();
      })
      .catch(_error => {
        addError(t('deviceStorage.files.actions.errorDownloadFile'), `${t('deviceStorage.files.actions.errorMsgDownloadFile')} ${fileId}`);
        hideLoader();
      });
  };

  const getActions = (disabledFiles) => {
    return [
      { key: 'file', label: t('deviceStorage.files.actions.downloadFile'), icon: 'download', handleClick: onDownloadClick, disabled: disabledFiles }
    ];
  }

  const headerRow = [
    t('deviceStorage.files.table.uploadedDate'),
    t('deviceStorage.files.table.fileId'),
    t('deviceStorage.files.table.deviceId'),
    t('deviceStorage.files.table.deviceType'),
    t('deviceStorage.files.table.deviceModel'),
    t('deviceStorage.files.table.version'),
    t('deviceStorage.files.table.tag'),
    t('deviceStorage.files.table.fileStatus'),
    t('common.labels.actions')];


  const renderBodyRow = ({ id, uploadedDate, deviceId, deviceType, deviceModel, version, tag, status, file }, i) => ({
    key: `row-${i}`,
    cells: [
      { key: 'uploadedDate', content: uploadedDate ? moment(uploadedDate).format('DD-MM-YYYY HH:mm:ss') : '-' },
      { key: 'id', content: id },
      { key: 'deviceId', content: deviceId },
      { key: 'deviceType', content: deviceType },
      { key: 'deviceModel', content: deviceModel },
      { key: 'version', content: version },
      { key: 'tag', content: tag },
      showStatus(status, i),
      { key: 'actions', as: TableCellActions, actions: getActions(status.toUpperCase() === "UPLOADED" ? false : true), rowId: id }
    ]
  });

  return(
    <div>
      <Menu secondary>
        <Menu.Menu>
          <Menu.Item>
            <FilterFileId
              onFileIdSelect={handleFilterChanged}
              loading={dsData.loading}/>
          </Menu.Item>
          <Menu.Item>
            <FilterDeviceId
              onDeviceIdSelect={handleFilterChanged}
              loading={dsData.loading}/>
          </Menu.Item>
          <Menu.Item>
            <FilterDeviceType
              onTypeSelect={handleFilterChanged}
              loading={dsData.loading}/>
          </Menu.Item>
          <Menu.Item>
            <FilterDeviceModel
              data={_.values(dsData.deviceStorageFiles)}
              onModelSelect={handleFilterChanged}
              loading={dsData.loading}/>
          </Menu.Item>
          <Menu.Item>
            <FilterFileTag
              data={_.values(dsData.deviceStorageFiles)}
              onTagSelect={handleFilterChanged}
              loading={dsData.loading}/>
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      <TablePaginated
        headerRow={headerRow}
        renderBodyRow={renderBodyRow}
        tableData={_.values(dsData.deviceStorageFiles)}
        loading={dsData.loading}
        totalItems={dsData.totalItems}
        setPagination={table.onPaginationChange}
      />
    </div>
  );
}

const TableDeviceStorageFiles = () => <TableProvider><TableComposition /></TableProvider>

export default TableDeviceStorageFiles;