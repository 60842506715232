import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import FilterSelect from "../../core/filters/FilterSelect";

const FilterDeviceType = ({ onTypeSelect, loading }) => {
  const [selectedType, setSelectedType] = React.useState('');
  const { t } = useTranslation();

  const handleTypeChanged = (value) => {
    setSelectedType(value);
    onTypeSelect('deviceType', value);
  };

  return (
    <FilterSelect
      selectedValue={selectedType}
      onValueChanged={handleTypeChanged}
      placeholder={t('deviceStorage.files.filters.deviceTypePlaceholder')}
      options={[
        { key: 1, text: 'LPR5', value: 'LPR5' },
        { key: 2, text: 'LPR6', value: 'LPR6' }
      ]}
      loading={loading}/>
  );
};

FilterDeviceType.propTypes = {
  onTypeSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}

export default FilterDeviceType;