import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Button, Icon, Menu } from "semantic-ui-react";

import { useTable, TableProvider } from "../../../providers/tableProvider";
import { useAlert } from "../../../providers/alertsProvider";
import { useLoader } from "../../../providers/loaderProvider";
import { hasRole } from "../../../utils/userRolesUtils";
import TablePaginated from "../../core/TablePaginated";
import TableCellActions from "../../core/TableCellActions";
import DialogLprType from "../dialog/DialogLprType";
import DialogModal from "../../core/DialogModal";

import { fetchLprTypes, addLprType } from "../../../actions/lpr/lprTypesAction";
import { deleteType } from "../../../helpers/handleDeleteType";
import { editType } from "../../../helpers/handleEditType";


function TableComposition () {
    let table = useTable();
    const lpr5Types = useSelector(state => state.lpr5Types);
    const authData = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const { addError, addSuccess } = useAlert();
    const { showLoader, hideLoader } = useLoader();
    const { roles } = authData.user;
    const { t } = useTranslation();

    const initialRender = React.useRef(false);
    
    React.useEffect(() => {
      loadLprTypes();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table.filters]);

    React.useEffect(() => {
      if (initialRender.current && lpr5Types.addSuccess){
        addSuccess(t('common.labels.success'), t('lprPs.types.actions.add.success'));
        hideLoader();
      } else if (initialRender.current && lpr5Types.error){
          addError(lpr5Types.error.error, lpr5Types.error.message);
          hideLoader();
      } else if (!initialRender.current){
        initialRender.current = true;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lpr5Types]);

    const loadLprTypes = () => {
      dispatch(fetchLprTypes(table.filters));
    }

    const addLprTy = (lprType) => { //NOSONAR
      showLoader();
      dispatch(addLprType(lprType));
    };

     const onDeleteClick = (lprTypeId) => {
        const url = `/lprPS/lpr/types/${lprTypeId}`;
        showLoader(t('lprPs.types.actions.delete.loader'));
        deleteType(url)
          .then(response => {
            hideLoader();
            loadLprTypes();
          })
          .catch(error => {
            addError(t('lprPs.types.actions.delete.errorDeleteType'), `${t('lprPs.types.actions.delete.errorMsgDeleteType')} ${lprTypeId}`);
            hideLoader();
          });
     };

     const onEditClick = (lprTypeId, body) => {
       const url = `/lprPS/lpr/types/${lprTypeId}`;
       showLoader(t('lprPs.types.actions.delete.loader'));
       editType(url, body)
         .then(response => {
           hideLoader();
           loadLprTypes();
         })
         .catch(error => {
           addError(t('lprPs.types.actions.edit.errorDeleteType'), `${t('lprPs.types.actions.edit.errorMsgDeleteType')} ${lprTypeId}`);
           hideLoader();
         });
     };

     const getActions = (lprTypeId, code, license, lprCountries, product) => {
       return [
         { key: 'edit', customElement:
           <DialogLprType key='edit'
             action='edit'
             header='lprPs.types.actions.edit.header'
             pHCode={code}
             pHLicense={license}
             pHLprCountries={lprCountries}
             pHProduct={product}
             trigger={<Button primary icon ><Icon name='edit' /></Button>}
             onClickAction={(lprType) => onEditClick(lprTypeId, lprType)}/>
         },
         { key: 'delete', customElement:
           <DialogModal key='delete'
             header='lprPs.types.actions.delete.header'
             content='lprPs.types.actions.delete.MsgConfirmation'
             trigger={<Button negative icon ><Icon name='trash alternate' /></Button>}
             onClickAction={() => onDeleteClick(lprTypeId)}/>
         }
       ];
     }

    const headerRow = [
      t('lprPs.types.table.code'),
      t('lprPs.types.table.license'),
      t('lprPs.types.table.lprCountries'),
      t('lprPs.types.table.product'),
      t('lprPs.types.table.actions')
    ];

    const renderBodyRow = ({ id, code, license, lprCountries, product }, i) => ({
      key: `row-${i}`,
      cells: [
        code,
        license,
        lprCountries,
        product,
        { key: 'actions', as: TableCellActions, actions: getActions(id, code, license, lprCountries, product), rowId: id }
      ]
    });

    return (
      <div>
          <Menu secondary>
            <Menu.Menu position='right'>
              {hasRole(roles, 'lpr', 'editor') &&
                <Menu.Item>
                  <DialogLprType
                    header='lprPs.types.actions.add.header'
                    pHCode='500'
                    pHLicense='lprPs.types.actions.basic.selectLicense'
                    pHLprCountries='lprPs.types.actions.basic.selectLprCountries'
                    pHProduct='lprPs.types.actions.basic.selectProduct'
                    trigger={<Button primary loading={lpr5Types.loading}><Icon name='plus circle' />{t('lprPs.types.menu.addType')}</Button>}
                    onClickAction={addLprTy}/>
                </Menu.Item>
              }
            </Menu.Menu>
          </Menu>

          <TablePaginated
            headerRow={headerRow}
            renderBodyRow={renderBodyRow}
            tableData={_.values(lpr5Types.types)}
            loading={lpr5Types.loading}
            totalItems={lpr5Types.totalItems}
            setPagination={table.onPaginationChange}
          />
      </div>
    );
}

const TableLprTypes = () => <TableProvider><TableComposition /></TableProvider>

export default TableLprTypes;