import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Segment, Tab } from "semantic-ui-react";

import Page from "./Page";
import TabDeviceStorageFiles from "../components/devicesStorage/tabs/TabDeviceStorageFiles";

const Administration = () => {
  const { t } = useTranslation();


  const sections = [
    { key: 'Dashboard', content: t('breadcrumb.dashboard'), link: true, as: Link, to: '/'},
    { key: 'deviceStorage', content: t('breadcrumb.deviceStorage'), active: true }
  ];

  const getVisiblePanes = () => {
    let panes = [];
      panes.push({ menuItem: t('deviceStorage.files.tabTitle'), render: () => <TabDeviceStorageFiles /> });
    return panes;
  }

  return (
    <Page sections={sections}>
      <Segment basic>
        {<Tab panes={getVisiblePanes()} />}
      </Segment>
    </Page>
  );
};

export default Administration;