import React from "react";
import { Dropdown } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

const FilterSelect = ({ selectedValue, onValueChanged, placeholder, options, loading }) => {
    const { t } = useTranslation();

    const handleValueOnChange = (e, { value }) => {
        onValueChanged(value);
    };

    return (
        <Dropdown
            selection
            clearable
            value={selectedValue}
            options={options}
            onChange={handleValueOnChange}
            placeholder={placeholder ? placeholder : t('common.labels.selectOne')}
            loading={loading}/>
    );
};

FilterSelect.propTypes = {
    selectedValue: PropTypes.any.isRequired,
    onValueChanged: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
          ]).isRequired,
        text: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired
    })).isRequired,
    loading: PropTypes.bool.isRequired
}

export default FilterSelect;