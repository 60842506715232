import _ from 'lodash';

export const handleError = (error) => {
    if (error.response){
        if (_.isEmpty(error.response.data) && error.response.status){
            return {
                error: error.response.status,
                message: error.response.message,
                status: error.response.status
            };
        } else if (error.response.data && error.response.data.message) {
            return {
                error: "Error",
                message: error.response.data.message
            };
        } else {
            return error.response.data;
        }
    }

    return {
        error: "Internal Server Error",
        message: "There was an error. Please try again later",
        status: 500
    };
};