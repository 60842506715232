import _ from "lodash";

export const getSearchString = (filters) => {
    let queryString;
    const filter = filters[0];
    if (filter.operation) {
        if(filter.operation === 'dateBetween') {
            const iniDate = parseInt(Date.parse(filter.value[0]) / 1000);
            // Added 23h 59min 59sec to filter with the entire end date inclusive (86399999 = 24*3600000-1)
            const endDate = parseInt((Date.parse(filter.value[1]) + 86399999) / 1000);
            queryString = `prodTimeStart=${iniDate}&prodTimeEnd=${endDate}`;
        } else {
            const operation = filter.operation === 'before' ? 'lte' : 'gte';
            const field = `${filter.field}%5B${operation}`;
            const timestamp = parseInt(Date.parse(filter.value) / 1000);
            queryString = `${field}%5D=${timestamp}`;
        }
    } else {
        queryString = `${filter.field}=${filter.value}`;
    }

    return queryString;
};
export const getOrder = (filters) => {
  const filter = filters[0];
  return filter.order;
}

export const addFiltersToQueryUrl = (query, filters) => {
    _.forEach(filters, filter => {
        query = addFilterToQuery(query, filter);
    });

    return query;
};

const addFilterToQuery = (query, filter) => {
    return { ...query, params: { ...query.params, [filter.field]: filter.value } };
}