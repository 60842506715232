export const ADMIN_FETCH_USERS = 'ADMIN_FETCH_USERS';
export const ADMIN_FETCH_USERS_REQUEST = 'ADMIN_FETCH_USERS_REQUEST';
export const ADMIN_FETCH_USERS_ERROR = 'ADMIN_FETCH_USERS_ERROR';

export const ADMIN_EDIT_USER = 'ADMIN_EDIT_USER';
export const ADMIN_EDIT_USER_REQUEST = 'ADMIN_EDIT_USER_REQUEST';
export const ADMIN_EDIT_USER_ERROR = 'ADMIN_EDIT_USER_ERROR';

export const ADMIN_DELETE_USER = 'ADMIN_DELETE_USER';
export const ADMIN_DELETE_USER_REQUEST = 'ADMIN_DELETE_USER_REQUEST';
export const ADMIN_DELETE_USER_ERROR = 'ADMIN_DELETE_USER_ERROR';

export const ADMIN_FETCH_USER_ACTIVITIES = 'ADMIN_FETCH_USER_ACTIVITIES';
export const ADMIN_FETCH_USER_ACTIVITIES_REQUEST = 'ADMIN_FETCH_USER_ACTIVITIES_REQUEST';
export const ADMIN_FETCH_USER_ACTIVITIES_ERROR = 'ADMIN_FETCH_USER_ACTIVITIES_ERROR';