import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "../../../providers/alertsProvider";
import { useTranslation } from "react-i18next";
import { Tab, Table } from "semantic-ui-react";

import { fetchLprApiVersion } from "../../../actions/lpr/lprConfigAction";

const TabLprConfig = () => {
  const lpr5ConfigData = useSelector(state => state.lpr5Config);
  const dispatch = useDispatch();

  const { addError } = useAlert();
  const { t } = useTranslation();
  
  React.useEffect(() => {
    dispatch(fetchLprApiVersion());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (lpr5ConfigData.error){
      addError(lpr5ConfigData.error.error, lpr5ConfigData.error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpr5ConfigData.error]);
    
  return (
    <Tab.Pane>
      <Table compact celled definition>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>{t('common.labels.value')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common.labels.description')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell collapsing>{t('lprPs.config.table.apiVersion')}</Table.Cell>
            <Table.Cell>{lpr5ConfigData ? lpr5ConfigData.version : ''}</Table.Cell>
            <Table.Cell>{t('lprPs.config.table.apiVersionDesc')}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>      
    </Tab.Pane>
  );
};

export default TabLprConfig;