import quercusApi from '../../apis/quercusApi';
import { handleError } from '../../helpers/handleError';
import {
    ADMIN_FETCH_USER_ACTIVITIES,
    ADMIN_FETCH_USER_ACTIVITIES_REQUEST,
    ADMIN_FETCH_USER_ACTIVITIES_ERROR
} from './types';

export const fetchUserActivities = ({ offset, limit }) => async (dispatch) => {
    dispatch({ type: ADMIN_FETCH_USER_ACTIVITIES_REQUEST });
    try {
        const query = {
            params: {
                size: limit,
                page: offset/limit,
                sort: "date,desc" 
            }
        };
        const response = await quercusApi.get('/api/v1/user-activities', query);
        dispatch({ type: ADMIN_FETCH_USER_ACTIVITIES, payload: response.data });
    } catch (error) {
        dispatch({ type: ADMIN_FETCH_USER_ACTIVITIES_ERROR, payload: handleError(error) });
    }
};

export const fetchUserActivitiesByObjectId = (objectId) => async (dispatch) => {
    dispatch({ type: ADMIN_FETCH_USER_ACTIVITIES_REQUEST });
    try {
        const query = {
            params: {
                size: 100,
                page: 0,
                objectId: objectId,
                sort: "date,desc" 
            }
        };
        const response = await quercusApi.get('/api/v1/user-activities', query);
        dispatch({ type: ADMIN_FETCH_USER_ACTIVITIES, payload: response.data });
    } catch (error) {
        dispatch({ type: ADMIN_FETCH_USER_ACTIVITIES_ERROR, payload: handleError(error) });
    }
};