import _ from "lodash";
import {
    RELEASES_FETCH_PACKAGES,
    RELEASES_FETCH_PACKAGES_REQUEST,
    RELEASES_FETCH_PACKAGES_ERROR,
    RELEASES_CREATE_PACKAGE,
    RELEASES_CREATE_PACKAGE_REQUEST,
    RELEASES_CREATE_PACKAGE_ERROR
} from '../../actions/releases/types';
  
  const INTIAL_STATE = {
    loading: false,
    error: null,
    packages: {},
    totalItems: 0,
    itemsPerPage: 0,
    addLoading: false,
    addSuccess: false
  };
  
  /* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
  export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
      case RELEASES_FETCH_PACKAGES_REQUEST:
        return { ...state, loading: true, packages: {}, totalItems: 0, error: null, itemsPerPage: action.payload, addSuccess: false, addLoading: false };
      case RELEASES_FETCH_PACKAGES:
        return { ...state, loading: false, packages: _.mapKeys(action.payload.list, "id"), totalItems: action.payload.totalItems, error: null };
      case RELEASES_FETCH_PACKAGES_ERROR:
        return { ...state, loading: false, packages: {}, totalItems: 0, error: action.payload };
      
      case RELEASES_CREATE_PACKAGE_REQUEST:
        return { ...state, addLoading: true, error: null, addSuccess: false };
      case RELEASES_CREATE_PACKAGE:
        if (_.values(state.packages).length < state.itemsPerPage){
          return { ...state, addLoading: false, packages: { ...state.packages, [action.payload.id]: action.payload }, totalItems: state.totalItems+1, error: null, addSuccess: true };
        }
        return { ...state, addLoading: false, totalItems: state.totalItems+1, error: null, addSuccess: true };
      case RELEASES_CREATE_PACKAGE_ERROR:
        return { ...state, addLoading: false, error: action.payload };
      
      default:
        return state;
    }
  };