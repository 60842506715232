import quercusApi from '../../apis/quercusApi';
import { handleError } from '../../helpers/handleError';
import { getSearchString, getOrder } from '../../utils/filterUtils';
import {
    LPR_FETCH_QCS,
    LPR_FETCH_QCS_REQUEST,
    LPR_FETCH_QCS_ERROR
} from './types';

export const fecthQcs = ({ offset, limit, queries }) => async (dispatch) => {
    dispatch({ type: LPR_FETCH_QCS_REQUEST });
    try {
        let query = {
            params: {
                limit: limit,
                offset: offset,
            }
        };
        if (queries.length > 0){
          query.params.searchString = getSearchString(queries);
          query.params.order = getOrder(queries);
        }

        const response = await quercusApi.get('/lprPS/lpr/qcs', query);
        dispatch({ type: LPR_FETCH_QCS, payload: response.data });
    } catch (error) {
        dispatch({ type: LPR_FETCH_QCS_ERROR, payload: handleError(error) });
    }
};