import React from "react";
import PropTypes from 'prop-types';
import { Breadcrumb, Message, Segment } from "semantic-ui-react";
import { useAlert } from "../providers/alertsProvider";

const HeaderPage = ({ sections }) => {
  const { alert, removeAlert } = useAlert();

  return (
    <Segment basic>
        <Breadcrumb size='huge' icon='right angle' sections={sections} />
        {alert &&
            <Message
              positive={alert.success}
              negative={alert.error}
              header={alert.header}
              content={alert.message}
              onDismiss={removeAlert}
            />
        }
    </Segment>
  );
};

HeaderPage.propTypes = {
  sections: PropTypes.array.isRequired
};

export default HeaderPage;