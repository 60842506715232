import React from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Icon } from 'semantic-ui-react';

import { signOutMethod } from '../actions/core/authAction';
import { googleLogout } from "@react-oauth/google";

function ButtonLogout() {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const onLogout = () => {
        /* we are not using one tap login, but not bad disable auto-select */
        googleLogout();
        dispatch(signOutMethod());
    };

    /* Signing out means signing out of the application, but not from the Google Account */
    return (
        <Button className="g_id_signout" onClick={onLogout} negative>
            <Icon name='sign-out'/> {t('common.actions.signOut')}
        </Button>
    );
}

export default ButtonLogout;