import React from "react";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { Button, Grid, Input, Modal } from "semantic-ui-react";

const DialogProduct = ({ trigger, onClickAction }) => {
    const [open, setOpen] = React.useState(false);
    const [product, setProduct] = React.useState({ id: '', description: '' });
    const { t } = useTranslation();

    const onOpen = () => {
        setOpen(true);
        setProduct({ id: '', description: '' });
    };

    const onClick = () => {
        onClickAction(product);
        setOpen(false);
    };

    const handleIdOnChange = (e, { value }) => {
        setProduct({ ...product, id: value });
    };

    const handleDescriptionOnChange = (e, { value }) => {
        setProduct({ ...product, description: value });
    };

    const isAnyFieldEmpty = () => {
        return product.id.length === 0 
            || product.description.length === 0;
    };

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={onOpen}
            closeOnDimmerClick={false}
            dimmer='blurring'
            size='small'
            open={open}
            trigger={trigger}
            >
            <Modal.Header>{t('releases.products.actions.create.header')}</Modal.Header>
            <Modal.Content>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            {t('releases.products.actions.create.id')}:
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <Input
                                placeholder='PRODUCT_ID'
                                value={product.id}
                                onChange={handleIdOnChange}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            {t('releases.products.actions.create.description')}:
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <Input
                                fluid
                                placeholder='Description of the product'
                                value={product.description}
                                onChange={handleDescriptionOnChange}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={() => setOpen(false)}>
                    {t('common.actions.cancel')}
                </Button>
                <Button onClick={onClick} primary disabled={isAnyFieldEmpty()}>
                    {t('common.actions.save')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

DialogProduct.propTypes = {
    trigger: PropTypes.element.isRequired,
    onClickAction: PropTypes.func.isRequired,
}

export default DialogProduct;