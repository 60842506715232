import React from "react";
import PropTypes from 'prop-types';
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Grid, Header, Icon, Input, List, Message, Modal } from "semantic-ui-react";

import { fetchPackages, createPackage } from "../../../actions/releases/releasesPackagesAction";

const DialogPackages = ({ trigger, productId }) => {
    const [open, setOpen] = React.useState(false);
    const [productPackage, setProductPackage] = React.useState({ id: '', description: '' });
    const { t } = useTranslation();
    const releasesPackages = useSelector(state => state.releasesPackages);
    const dispatch = useDispatch();

    const initialRender = React.useRef(false);

    React.useEffect(() => {
        if (initialRender.current && releasesPackages.addSuccess){
            setProductPackage({ id: '', description: '' });
        } else if (!initialRender.current){
            initialRender.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [releasesPackages]);

    const onOpen = () => {
        setOpen(true);
        setProductPackage({ id: '', description: '' });
        loadPackages();
    };

    const onClose = () => {
        setOpen(false)
    };

    const loadPackages = () => {
        dispatch(fetchPackages(productId, { offset: 0, limit: 100 }));
    }

    const addPackage = () => {
        dispatch(createPackage(productId, productPackage));
    }

    const handleIdOnChange = (e, { value }) => {
        setProductPackage({ ...productPackage, id: value });
    };

    const handleDescriptionOnChange = (e, { value }) => {
        setProductPackage({ ...productPackage, description: value });
    };

    const isAnyFieldEmpty = () => {
        return productPackage.id.length === 0 
            || productPackage.description.length === 0;
    };

    return (
        <Modal
            onOpen={onOpen}
            closeOnDimmerClick={false}
            dimmer='blurring'
            size='small'
            open={open}
            trigger={trigger}
            >
            <Modal.Header>{productId}</Modal.Header>
            <Modal.Content scrolling>
                {releasesPackages.error &&
                    <Message
                        negative={true}
                        header={releasesPackages.error.error}
                        content={releasesPackages.error.message}
                    />
                }
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Input
                                placeholder={t('releases.products.packages.idPlaceholder')}
                                value={productPackage.id}
                                onChange={handleIdOnChange}
                                style={{ marginRight: '0.5em' }}/>
                            /
                            <Input
                                placeholder={t('releases.products.packages.descriptionPlaceholder')}
                                value={productPackage.description}
                                onChange={handleDescriptionOnChange}
                                style={{ marginLeft: '0.5em', marginRight: '0.5em' }}/>
                            <Button primary animated onClick={addPackage} 
                                loading={releasesPackages.addLoading || releasesPackages.loading}
                                disabled={isAnyFieldEmpty()}>
                                <Button.Content visible>{t('releases.products.packages.addButton')}</Button.Content>
                                <Button.Content hidden>
                                    <Icon name='plus circle' />
                                </Button.Content>
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Header as='h3'>{t('releases.products.packages.header')} </Header>
                            <List divided relaxed>
                                {(!releasesPackages.packages || _.values(releasesPackages.packages).length === 0)
                                    && !releasesPackages.loading 
                                    && <List.Item>{t('releases.products.packages.noData')}</List.Item>}
                                {releasesPackages.loading 
                                    && <List.Item>{t('releases.products.packages.loading')}</List.Item>}
                                {releasesPackages.packages 
                                    && _.values(releasesPackages.packages).length > 0 
                                    && _.values(releasesPackages.packages).map(p => {
                                        return (
                                            <List.Item key={p.id}>
                                                <List.Content>
                                                    <List.Header>{p.id}</List.Header>
                                                    <List.Description>{p.description}</List.Description>
                                                </List.Content>
                                            </List.Item>
                                        )
                                })}
                            </List>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button primary onClick={onClose}>
                    {t('common.actions.close')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

DialogPackages.propTypes = {
    trigger: PropTypes.element.isRequired,
    productId: PropTypes.string.isRequired
}

export default DialogPackages;