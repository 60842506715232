import React from "react";
import PropTypes from 'prop-types';
import TableBasic from "./TableBasic";
import { useTranslation } from "react-i18next";
import { Dropdown, Icon, Pagination, Table } from "semantic-ui-react";

const TablePaginated = ({ headerRow, renderBodyRow, tableData, loading, itemsOptions, defaultSelectedPage, defaultItemsPerPage, totalItems, setPagination }) => {
    const [selectedPage, setSelectedPage] = React.useState(defaultSelectedPage);
    const [itemsPage, setItemsPage] = React.useState(defaultItemsPerPage);
    const { t } = useTranslation();

    const handlePaginationChange = (e, { activePage }) => {
        setSelectedPage(activePage);
        setPagination((activePage-1)*itemsPage, itemsPage );
    };

    const handleDropdownChange = (e, { value }) => {
        setItemsPage(value);
        setSelectedPage(1);
        setPagination(0, value);
    };

    const calculateTotalPages = () => {
        if (!totalItems || totalItems <= 0){
            return 0;
        }

        return Math.ceil(totalItems/itemsPage);
    }

    const renderFooter = () => {
        return (
            <Table.Row>
                <Table.HeaderCell colSpan={headerRow.length}>
                    {t('common.labels.itemsPerPage')}:
                    <Dropdown 
                        compact
                        selection
                        options={itemsOptions} 
                        value={itemsPage}
                        style={{ marginLeft: '0.5em', marginRight: '0.5em' }}
                        onChange={handleDropdownChange}/>
                    {t('common.labels.totalItems')} {totalItems}
                    <Pagination
                        activePage={selectedPage}
                        onPageChange={handlePaginationChange}
                        floated='right'
                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                        firstItem={null}
                        lastItem={null}
                        prevItem={{ content: <Icon name='angle left' />, icon: true }}
                        nextItem={{ content: <Icon name='angle right' />, icon: true }}
                        totalPages={calculateTotalPages()}
                    />
                </Table.HeaderCell>
            </Table.Row>
        );
    };

    return (
        <TableBasic
            striped
            headerRow={headerRow}
            renderBodyRow={renderBodyRow}
            tableData={tableData}
            loading={loading}
            footerRow={renderFooter()}/>
    );
};

TablePaginated.defaultProps = {
    itemsOptions: [
        { key: 1, text: '5', value: 5 },
        { key: 2, text: '20', value: 20 },
        { key: 3, text: '50', value: 50 }
    ],
    defaultSelectedPage: 1,
    defaultItemsPerPage: 20,
};

TablePaginated.propTypes = {
    headerRow: PropTypes.array.isRequired,
    renderBodyRow: PropTypes.func.isRequired, 
    tableData: PropTypes.array, 
    itemsOptions: PropTypes.array, 
    loading: PropTypes.bool.isRequired,
    defaultSelectedPage: PropTypes.number,
    defaultItemsPerPage: PropTypes.number,
    totalItems: PropTypes.number.isRequired,
    setPagination: PropTypes.func.isRequired
};

export default TablePaginated;