import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Segment, Tab } from "semantic-ui-react";

import Page from "./Page";
import TabScProductions from "../components/spotControl/tabs/TabScProductions";
import TabScQc from "../components/spotControl/tabs/TabScQc";
import TabScConfig from "../components/spotControl/tabs/TabScConfig";

const SpotControlIndoor = () => {
  const { t } = useTranslation();

  const sections = [
    { key: 'Dashboard', content: t('breadcrumb.dashboard'), link: true, as: Link, to: '/'},
    { key: 'scIndoor', content: t('breadcrumb.sc2'), active: true }
  ];

  const panes = [
    { menuItem: t('sc2Ps.productions.tabTitle'), render: () => <TabScProductions/> },
    { menuItem: t('sc2Ps.qcs.tabTitle'), render: () => <TabScQc/> },
    { menuItem: t('sc2Ps.config.tabTitle'), render: () => <TabScConfig/> }
  ];
    
  return (
    <Page sections={sections}>
      <Segment basic>
        <Tab panes={panes} />
      </Segment>
    </Page>
  );
};

export default SpotControlIndoor;