import _ from 'lodash';

export const userRoles = [
    { key: 'admin.users', value: 'admin.users', text: 'Administration users' },
    { key: 'releases.admin', value: 'releases.admin', text: 'Releases administrator' },
    { key: 'release.developer', value: 'releases.developer', text: 'Releases developer' },
    { key: 'release.public', value: 'releases.public', text: 'Releases public' },
    { key: 'lpr.viewer', value: 'lpr.viewer', text: 'LPR viewer' },
    { key: 'lpr.editor', value: 'lpr.editor', text: 'LPR editor' },
    { key: 'sc2.viewer', value: 'sc2.viewer', text: 'SC2 viewer' },
    { key: 'sc2.editor', value: 'sc2.editor', text: 'SC2 editor' },
  ];

export const hasRole = (roles, section, role) => {
  if (roles && roles.length > 0){
      const hasSectionList = _.filter(roles, (item) => _.startsWith(item, section));
      if (hasSectionList.length === 0){
          return false;
      } else if (role){
          const hasRoleList = _.filter(hasSectionList, (item) => _.endsWith(item, role));
          return hasRoleList.length > 0;
      } else {
          return true;
      }
  }

  return false;
};