import { 
    ADMIN_FETCH_USER_ACTIVITIES,
    ADMIN_FETCH_USER_ACTIVITIES_REQUEST,
    ADMIN_FETCH_USER_ACTIVITIES_ERROR
} from '../../actions/administration/types';

const INTIAL_STATE = {
    loading: false,
    error: null,
    list: [],
    totalItems: 0
  };
  
  /* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
  export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
      case ADMIN_FETCH_USER_ACTIVITIES_REQUEST:
        return { ...state, loading: true, list: [], totalItems: 0, error: null };
      case ADMIN_FETCH_USER_ACTIVITIES:
        return { ...state, loading: false, list: action.payload.list, totalItems: action.payload.totalItems, error: null };
      case ADMIN_FETCH_USER_ACTIVITIES_ERROR:
        return { ...state, loading: false, list: [], totalItems: 0, error: action.payload };
      
      default:
        return state;
    }
  };