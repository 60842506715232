import quercusApi from '../../apis/quercusApi';
import { handleError } from '../../helpers/handleError';
import {
    LPR_FETCH_TYPES,
    LPR_FETCH_TYPES_REQUEST,
    LPR_FETCH_TYPES_ERROR,
    LPR_ADD_TYPE,
    LPR_ADD_TYPE_REQUEST,
    LPR_ADD_TYPE_ERROR
} from './types';

export const fetchLprTypes = ({ offset, limit }) => async (dispatch) => {
    dispatch({ type: LPR_FETCH_TYPES_REQUEST, payload: limit });
    try {
        let query = {
            params: {
                limit: limit,
                offset: offset 
            }
        };
        const response = await quercusApi.get('/lprPS/lpr/types', query);
      
        dispatch({ type: LPR_FETCH_TYPES, payload: response.data });
    } catch (error) {
        dispatch({ type: LPR_FETCH_TYPES_ERROR, payload: handleError(error) });
    }
};

export const addLprType = (lprType) => async (dispatch) => {
    dispatch({ type: LPR_ADD_TYPE_REQUEST });
    try {
        const response = await quercusApi.post('/lprPS/lpr/types', lprType);
        dispatch({ type: LPR_ADD_TYPE, payload: response.data });
    } catch (error) {
        dispatch({ type: LPR_ADD_TYPE_ERROR, payload: handleError(error) });
    }
};