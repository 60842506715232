import { 
    SIGN_IN,
    SIGN_IN_REQUEST,
    SIGN_IN_ERROR,
    SIGN_OUT } from '../../actions/core/types';
  
  const INTIAL_STATE = {
    loading: false,
    isSignedIn: false,
    registerError: false,
    user: null
  };
  
  /* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
  export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
      case SIGN_IN_REQUEST:
        return { 
          loading: true,
          registerError: false 
        };
      case SIGN_IN:
        return {
          isSignedIn: true,
          registerError: false, 
          user: action.payload 
        };
      case SIGN_IN_ERROR:
        return {
          isSignedIn: false,
          registerError: true, 
          user: null
        };
      case SIGN_OUT:
        return {
          isSignedIn: false, 
          user: null 
        };
      default:
        return state;
    }
  };