import quercusApi from '../../apis/quercusApi';
import { handleError } from '../../helpers/handleError';
import {
    RELEASES_FETCH_PRODUCTS,
    RELEASES_FETCH_PRODUCTS_REQUEST,
    RELEASES_FETCH_PRODUCTS_ERROR,
    RELEASES_CREATE_PRODUCT,
    RELEASES_CREATE_PRODUCT_REQUEST,
    RELEASES_CREATE_PRODUCT_ERROR
} from './types';

export const fetchProducts = ({ offset, limit }) => async (dispatch) => {
    dispatch({ type: RELEASES_FETCH_PRODUCTS_REQUEST, payload: limit });
    try {
        let query = {
            params: {
                size: limit,
                page: offset/limit 
            }
        };
        const response = await quercusApi.get('/api/v1/products', query);
      
        dispatch({ type: RELEASES_FETCH_PRODUCTS, payload: response.data });
    } catch (error) {
        dispatch({ type: RELEASES_FETCH_PRODUCTS_ERROR, payload: handleError(error) });
    }
};

export const createProduct = (productForm) => async (dispatch) => {
    dispatch({ type: RELEASES_CREATE_PRODUCT_REQUEST });
    try {
        const response = await quercusApi.post('/api/v1/products', productForm);
        dispatch({ type: RELEASES_CREATE_PRODUCT, payload: response.data });
    } catch (error) {
        dispatch({ type: RELEASES_CREATE_PRODUCT_ERROR, payload: handleError(error) });
    }
};