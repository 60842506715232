import React from "react";
import { Tab } from "semantic-ui-react";

import TableScProductions from "../tables/TableScProductions";

const TabScProductions = () => {
  return (
    <Tab.Pane>
        <TableScProductions />
    </Tab.Pane>
  );
};

export default TabScProductions;