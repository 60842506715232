import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Segment, Tab } from "semantic-ui-react";
import { hasRole } from "../../utils/userRolesUtils";

import Page from "../Page";
import TabProducts from "../../components/releases/tabs/TabProducts";
import TabReleases from "../../components/releases/tabs/TabReleases";

const ReleasesMenu = () => {
  const { t } = useTranslation();
  const authData = useSelector(state => state.auth);
  const { roles } = authData.user;

  const sections = [
    { key: 'Dashboard', content: t('breadcrumb.dashboard'), link: true, as: Link, to: '/'},
    { key: 'releases', content: t('breadcrumb.releases'), active: true }
  ];

  const getVisiblePanes = () => {
    let panes = [];

    panes.push({ menuItem: t('releases.list.tabTitle'), render: () => <TabReleases/> });
    if (hasRole(roles, 'releases', 'admin')){
      panes.push({ menuItem: t('releases.products.tabTitle'), render: () => <TabProducts/> });
    }

    return panes;
  }
    
  return (
    <Page sections={sections}>
      <Segment basic>
        <Tab panes={getVisiblePanes()} />
      </Segment>
    </Page>
  );
};

export default ReleasesMenu;