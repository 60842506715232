import _ from "lodash";
import {
    RELEASES_FETCH_RELEASE,
    RELEASES_FETCH_RELEASE_REQUEST,
    RELEASES_FETCH_RELEASE_ERROR,
    RELEASES_FETCH_RELEASES,
    RELEASES_FETCH_RELEASES_REQUEST,
    RELEASES_FETCH_RELEASES_ERROR
} from '../../actions/releases/types';
  
  const INTIAL_STATE = {
    loading: false,
    error: null,
    releases: {},
    totalItems: 0,
    itemsPerPage: 0
  };
  
  /* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
  export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
      case RELEASES_FETCH_RELEASES_REQUEST:
        return { ...state, loading: true, releases: {}, totalItems: 0, error: null, itemsPerPage: action.payload };
      case RELEASES_FETCH_RELEASES:
        return { ...state, loading: false, releases: _.mapKeys(action.payload.list, "id"), totalItems: action.payload.totalItems, error: null };
      case RELEASES_FETCH_RELEASES_ERROR:
        return { ...state, loading: false, releases: {}, totalItems: 0, error: action.payload };
      
      case RELEASES_FETCH_RELEASE_REQUEST:
        return { ...state, loading: true };
      case RELEASES_FETCH_RELEASE:
        return { ...state, loading: false, releases: { ...state.releases, [action.payload.id]: action.payload }, error: null };
      case RELEASES_FETCH_RELEASE_ERROR:
        return { ...state, loading: false, error: action.payload };
      
      default:
        return state;
    }
  };