import { createContext, useContext, useState } from "react";
import _ from "lodash";

/**
 * Define context
 */
const tableContext = createContext();
/**
 * 
 * @returns 
 * Use context
 */
function useTable() {
  return useContext(tableContext);
}
/**
 * 
 * @returns 
 * Table Provider
 */
function useTableProvider() {
  const [filters, setFilters] = useState({ offset: 0, limit: 20, queries: [] })
  const onAddFilter = (filter) => {
    if (_.find(filters.queries, ['field', filter.field])){
      const newQueries = filters.queries.map(f => {
        if(f.field === filter.field) {
          return filter;
        }
        return {...f};
      });
      setFilters({ ...filters, offset: 0, queries: newQueries });
    } else {
      setFilters({ ...filters, offset: 0, queries: [...filters.queries, filter] });
    }
  }
  const onResetFilter = (filter) => {
    setFilters({ ...filters, offset: 0, queries: [filter] });
  }
  const onDeleteFilter = (field) => {
    setFilters({ ...filters, offset: 0, queries: filters.queries.filter(query => query.field !== field) });
  }
  const onDeleteFilters = (fields) => {
    setFilters({ ...filters, offset: 0, queries: filters.queries.filter(query => !fields.includes(query.field)) });
  }
  const onClearFilters = () => {
    setFilters({ ...filters, offset: 0, queries: [] });
  }
  const onPaginationChange = (offset, limit) => {
    setFilters({ ...filters, offset: offset, limit: limit });
  }

  return {
    filters,
    onAddFilter,
    onResetFilter,
    onDeleteFilter,
    onDeleteFilters,
    onClearFilters,
    onPaginationChange
  };
}
/**
 * 
 * @param {*} param0 
 * @returns 
 */
function TableProvider({ children }) {
  const table = useTableProvider();
  return (
    <tableContext.Provider value={table}>
      {children}
    </tableContext.Provider>
  );
}
export {
  TableProvider,
  useTable
}