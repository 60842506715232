import _ from "lodash";
import { 
    LPR_FETCH_TYPES,
    LPR_FETCH_TYPES_REQUEST,
    LPR_FETCH_TYPES_ERROR,
    LPR_ADD_TYPE,
    LPR_ADD_TYPE_REQUEST,
    LPR_ADD_TYPE_ERROR
} from '../../actions/lpr/types';
  
  const INTIAL_STATE = {
    loading: false,
    error: null,
    types: {},
    totalItems: 0,
    itemsPerPage: 0,
    addSuccess: false
  };
  
  /* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
  export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
      case LPR_FETCH_TYPES_REQUEST:
        return { ...state, loading: true, types: {}, totalItems: 0, error: null, itemsPerPage: action.payload, addSuccess: false };
      case LPR_FETCH_TYPES:
        return { ...state, loading: false, types: _.mapKeys(action.payload.list, "id"), totalItems: action.payload.pagination.totalItems, error: null };
      case LPR_FETCH_TYPES_ERROR:
        return { ...state, loading: false, types: {}, totalItems: 0, error: action.payload };
      
      case LPR_ADD_TYPE_REQUEST:
        return { ...state, loading: true, error: null, addSuccess: false };
      case LPR_ADD_TYPE:
        if (_.values(state.types).length < state.itemsPerPage){
          return { ...state, loading: false, types: { ...state.types, [action.payload.id]: action.payload }, totalItems: state.totalItems+1, error: null, addSuccess: true };
        }
        return { ...state, loading: false, totalItems: state.totalItems+1, error: null, addSuccess: true };
      case LPR_ADD_TYPE_ERROR:
        return { ...state, loading: false, error: action.payload };
      
      default:
        return state;
    }
  };