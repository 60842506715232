import axios from 'axios';

let instance = axios.create({
    baseURL: process.env.REACT_APP_QUERCUS_API_BASE_URL
});

// Set the AUTH token for any request
instance.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem('token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
});

export default instance;