import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Button, Icon, Popup, Table } from "semantic-ui-react";
import DialogModal from "./DialogModal";

const TableCellActions = ({ actions, rowId }) => {
  return (
    <Table.Cell>
      {actions.map(action => {
        if (action.customElement){
          return action.customElement;
        } else if (action.modal){
          return (
              <DialogModal
                key={action.key} 
                header={action.modalHeader} 
                content={action.modalContent}
                trigger={
                  <Button primary icon disabled={action.disabled}>
                    <Icon name={action.icon} />
                  </Button>
                }
                onClickAction={() => action.handleClick(rowId)} />
          );
        } else if (action.link){
          return (
              <Popup key={action.key} content={action.label} trigger={
                <Button primary icon as={Link} to={action.link} disabled={action.disabled}>
                  <Icon name={action.icon} />
                </Button>
            } />
          );
        } else if(action.negative) {
          return (
            <Popup key={action.key} content={action.label} trigger={
              <Button negative icon onClick={() => action.handleClick(rowId)} disabled={action.disabled}>
                <Icon name={action.icon} />
              </Button>
            } />
          );
        } else {
          return (
              <Popup key={action.key} content={action.label} trigger={
                <Button primary icon onClick={() => action.handleClick(rowId)} disabled={action.disabled}>
                  <Icon name={action.icon} />
                </Button>
            } />
          );
        }
      })}
    </Table.Cell>
  );
};

TableCellActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]).isRequired,
    label: PropTypes.string,
    icon: PropTypes.string,
    handleClick: PropTypes.func,
    disabled: PropTypes.bool,
    customElement: PropTypes.object,
    link: PropTypes.string
  })).isRequired,
  rowId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
};

export default TableCellActions;