import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import FilterSelect from "../../core/filters/FilterSelect";

const FilterFileTag = ({ data, onTagSelect, loading }) => {
  const [selectedTag, setSelectedTag] = React.useState('');
  const [tags, setTags] = React.useState([]);
  const { t } = useTranslation();

  React.useEffect(() => {
    setTags(_.values(_.uniqBy(data, 'tag')).map(p => {
      return { key: p.id, text: p.tag, value: p.tag }
    }));
  }, [data]);

  const handleTagChanged = (value) => {
    setSelectedTag(value);
    onTagSelect('tag', value);
  };

  return (
    <FilterSelect
      selectedValue={selectedTag}
      onValueChanged={handleTagChanged}
      placeholder={t('deviceStorage.files.filters.tagPlaceholder')}
      options={tags}
      loading={loading}/>
  )
};

FilterFileTag.propTypes = {
  onTagSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}

export default FilterFileTag;