import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import FilterSelect from "../../core/filters/FilterSelect";


const FilterDeviceModel = ({ data, onModelSelect, loading }) => {
  const [selectedModel, setSelectedModel] = React.useState('');
  const [models, setModels] = React.useState([]);
  const { t } = useTranslation();

  React.useEffect(() => {
    setModels(_.values(_.uniqBy(data, 'deviceModel')).map(p => {
      return { key: p.id, text: p.deviceModel, value: p.deviceModel }
    }));
  }, [data]);

  const handleDevModelChanged = (value) => {
    setSelectedModel(value);
    onModelSelect('deviceModel', value);
  };

  return (
    <FilterSelect
      selectedValue={selectedModel}
      onValueChanged={handleDevModelChanged}
      placeholder={t('deviceStorage.files.filters.deviceModelPlaceholder')}
      options={models}
      loading={loading}/>
  );
};

FilterDeviceModel.propTypes = {
  onModelSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}

export default FilterDeviceModel;