import _ from "lodash";
import { 
    ADMIN_FETCH_USERS,
    ADMIN_FETCH_USERS_REQUEST,
    ADMIN_FETCH_USERS_ERROR,
    ADMIN_EDIT_USER,
    ADMIN_EDIT_USER_REQUEST,
    ADMIN_EDIT_USER_ERROR,
    ADMIN_DELETE_USER,
    ADMIN_DELETE_USER_REQUEST,
    ADMIN_DELETE_USER_ERROR
} from '../../actions/administration/types';

const INTIAL_STATE = {
    loading: false,
    error: null,
    users: {},
    totalItems: 0,
    deleteSuccess: false,
    editSuccess: false
  };
  
  /* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
  export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
      case ADMIN_FETCH_USERS_REQUEST:
        return { ...state, loading: true, users: {}, totalItems: 0, error: null, deleteSuccess: false, editSuccess: false };
      case ADMIN_FETCH_USERS:
        return { ...state, loading: false, users: _.mapKeys(action.payload.list, "id"), totalItems: action.payload.totalItems, error: null };
      case ADMIN_FETCH_USERS_ERROR:
        return { ...state, loading: false, users: {}, totalItems: 0, error: action.payload };

      case ADMIN_EDIT_USER_REQUEST:
        return { ...state, loading: true, error: null, deleteSuccess: false, editSuccess: false };
      case ADMIN_EDIT_USER:
        return { ...state, loading: false, users: { ...state.users, [action.payload.id]: action.payload }, error: null, editSuccess: true };
      case ADMIN_EDIT_USER_ERROR:
        return { ...state, loading: false, error: action.payload };

      case ADMIN_DELETE_USER_REQUEST:
        return { ...state, loading: true, error: null, deleteSuccess: false, editSuccess: false };
      case ADMIN_DELETE_USER:
        return { ...state, loading: false, users: { ...state.users, [action.payload.id]: action.payload }, error: null, deleteSuccess: true };
      case ADMIN_DELETE_USER_ERROR:
        return { ...state, loading: false, error: action.payload };
      
      default:
        return state;
    }
  };