import axios from 'axios';
import quercusApi from '../apis/quercusApi';

export const getDownloadFile = async (url) => {
    return quercusApi.get(url, {
        responseType: 'blob',
    });
};

export const getDownloadFileFromDeviceStorage = async (url) => {
    const response = await quercusApi.get(url);
    return axios.get(response.data, {
        responseType: 'blob',
    });
};

export const getDownloadFileFromReleaseService = async (url) => {
    const signedUrl = url.replace(/^.*\/\/[^/]+/, '');
    const response = await quercusApi.get(signedUrl);
    return axios.get(response.data, {
        responseType: 'blob',
    });
};