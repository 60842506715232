import React from "react";
import { Tab } from "semantic-ui-react";

import TableLprProductions from "../tables/TableLprProductions";

const TabLprProductions = () => {
  return (
    <Tab.Pane>
        <TableLprProductions />
    </Tab.Pane>
  );
};

export default TabLprProductions;