import quercusApi from '../../apis/quercusApi';
import { handleError } from '../../helpers/handleError';
import {
    ADMIN_FETCH_USERS,
    ADMIN_FETCH_USERS_REQUEST,
    ADMIN_FETCH_USERS_ERROR,
    ADMIN_EDIT_USER,
    ADMIN_EDIT_USER_REQUEST,
    ADMIN_EDIT_USER_ERROR,
    ADMIN_DELETE_USER,
    ADMIN_DELETE_USER_REQUEST,
    ADMIN_DELETE_USER_ERROR
} from './types';

export const fetchUsers = ({ offset, limit }) => async (dispatch) => {
    dispatch({ type: ADMIN_FETCH_USERS_REQUEST });
    try {
        const query = {
            params: {
                size: limit,
                page: offset/limit,
                sort: "name,asc" 
            }
        };
        const response = await quercusApi.get('/api/users', query);
        dispatch({ type: ADMIN_FETCH_USERS, payload: response.data });
    } catch (error) {
        dispatch({ type: ADMIN_FETCH_USERS_ERROR, payload: handleError(error) });
    }
};

export const editUser = ({ id, roles }) => async (dispatch) => {
    dispatch({ type: ADMIN_EDIT_USER_REQUEST });
    try {
        const form = {
            roles: roles
        };
        const response = await quercusApi.patch(`/api/users/${id}`, form);
        dispatch({ type: ADMIN_EDIT_USER, payload: response.data });
    } catch (error) {
        dispatch({ type: ADMIN_EDIT_USER_ERROR, payload: handleError(error) });
    }
};

export const deleteUser = userId => async (dispatch) => {
    dispatch({ type: ADMIN_DELETE_USER_REQUEST });
    try {
        const response = await quercusApi.delete(`/api/users/${userId}`);
        dispatch({ type: ADMIN_DELETE_USER, payload: response.data });
    } catch (error) {
        dispatch({ type: ADMIN_DELETE_USER_ERROR, payload: handleError(error) });
    }
};