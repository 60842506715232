import _ from "lodash";
import {
  RELEASES_FETCH_FILES,
  RELEASES_FETCH_FILES_REQUEST,
  RELEASES_FETCH_FILES_ERROR
} from '../../actions/releases/types';
  
  const INTIAL_STATE = {
    loading: false,
    error: null,
    files: {},
    totalItems: 0,
    itemsPerPage: 0
  };
  
  /* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
  export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
      case RELEASES_FETCH_FILES_REQUEST:
        return { ...state, loading: true, files: {}, totalItems: 0, error: null, itemsPerPage: action.payload };
      case RELEASES_FETCH_FILES:
        return { ...state, loading: false, files: _.mapKeys(action.payload.list, "fileId"), totalItems: action.payload.totalItems, error: null };
      case RELEASES_FETCH_FILES_ERROR:
        return { ...state, loading: false, files: {}, totalItems: 0, error: action.payload };
      
      default:
        return state;
    }
  };