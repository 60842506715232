import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { saveAs } from "file-saver";

import { useTable, TableProvider } from "../../../providers/tableProvider";
import { useAlert } from "../../../providers/alertsProvider";
import { useLoader } from "../../../providers/loaderProvider";
import TablePaginated from "../../core/TablePaginated";
import TableCellActions from "../../core/TableCellActions";
import Filter from "../../core/Filter";

import { fecthQcs } from "../../../actions/lpr/lprQcsAction";
import { getDownloadFile } from "../../../helpers/handleDownloadFile";

function TableComposition () {
    let table = useTable();
    const lpr5Qcs = useSelector(state => state.lpr5Qcs);
    const dispatch = useDispatch();
    const { addError } = useAlert();
    const { showLoader, hideLoader } = useLoader();
    const { t } = useTranslation();
    
    React.useEffect(() => {
      loadQcs();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table.filters]);

    React.useEffect(() => {
      if (lpr5Qcs.error){
        addError(lpr5Qcs.error.error, lpr5Qcs.error.message);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lpr5Qcs.error]);

    const loadQcs = () => {
      dispatch(fecthQcs(table.filters));
    }

    const onLogsClick = (qcId) => {
      const url = `/lprPS/lpr/qc/${qcId}/logs`;
      showLoader(t('lprPs.qcs.actions.downloadLogsLoader'));
      getDownloadFile(url)
        .then(response => {
          saveAs(response.data, `logs_lpr_qc_${qcId}.zip`);
          hideLoader();
        })
        .catch(error => {
          addError(t('lprPs.qcs.actions.errorDownloadLogs'), `${t('lprPs.qcs.actions.errorMsgDownloadLogs')} ${qcId}`);
          hideLoader();
        });
    };

    const onImagesClick = (qcId) => {
      const url = `/lprPS/lpr/qc/${qcId}/images`;
      showLoader(t('lprPs.qcs.actions.downloadImagesLoader'));
      getDownloadFile(url)
        .then(response => {
          saveAs(response.data, `images_lpr_qc_${qcId}.zip`);
          hideLoader();
        })
        .catch(error => {
          addError(t('lprPs.qcs.actions.errorDownloadImages'), `${t('lprPs.qcs.actions.errorMsgDownloadImages')} ${qcId}`);
          hideLoader();
        });
    };

    const getActions = (disabledLogs, disabledImages) => {
      return [
        { key: 'logs', label: t('lprPs.qcs.actions.downloadLogs'), icon: 'file alternate outline', handleClick: onLogsClick, disabled: disabledLogs },
        { key: 'images', label: t('lprPs.qcs.actions.downloadImages'), icon: 'image outline', handleClick: onImagesClick, disabled: disabledImages }
      ];
    }

    const filters = [
      { key: 1, text: t('lprPs.qcs.filter.serialNumber'), value: 'serialNumber', field: 'serialNumber', placeholder: t('lprPs.qcs.filter.serialNumberPlaceholder'), type: 'input' },
      { key: 2, text: t('lprPs.qcs.filter.producer'), value: 'producer', field: 'qcProducer', placeholder: t('lprPs.qcs.filter.producerPlaceholder'), type: 'input' },
      { key: 3, text: t('lprPs.qcs.filter.result'), value: 'result', field: 'result', placeholder: t('lprPs.qcs.filter.resultPlaceholder'), type: 'select',  options: [{ key: 1, text: 'Success', value: true }, { key: 2, text: 'Fail', value: false }] },
      { key: 4, text: t('lprPs.qcs.filter.qcDateBefore'), value: 'timestampBefore', field: 'qcTimestamp', type: 'dateBefore' },
      { key: 5, text: t('lprPs.qcs.filter.qcDateAfter'), value: 'timestampAfter', field: 'qcTimestamp', type: 'dateAfter' },
      { key: 6, text: t('lprPs.qcs.filter.productionDateBetween'), value: 'timestamp', field: 'prodTimestamp', type: 'dateBetween'}

    ];

    const headerRow = [
      t('lprPs.qcs.table.serialNumber'),
      t('lprPs.qcs.table.date'),
      t('lprPs.qcs.table.producer'),
      t('lprPs.qcs.table.result'),
      t('common.labels.actions')];

    const renderBodyRow = ({ id, serialNumber, timestamp, producer, result, log, images }, i) => ({
      key: `row-${i}`,
      cells: [
        serialNumber || '-',
        timestamp ? moment(timestamp*1000).format('DD-MM-YYYY HH:mm:ss') : '-',
        producer,
        result ? t('common.labels.success') : t('common.labels.fail'),
        { key: 'actions', as: TableCellActions, actions: getActions(log && log.length > 0 ? false : true, images && images.length > 0 ? false : true), rowId: id }
      ],
    });

    return (
      <div>
          <Filter
            filters={filters}
            onAddFilter={table.onResetFilter}
            onClearFilters={table.onClearFilters}
            loading={lpr5Qcs.loading}
          />
          <TablePaginated
            headerRow={headerRow}
            renderBodyRow={renderBodyRow}
            tableData={lpr5Qcs.qcs}
            loading={lpr5Qcs.loading}
            totalItems={lpr5Qcs.totalItems}
            setPagination={table.onPaginationChange}
          />
      </div>
    );
}

const TableLprQcs = () => <TableProvider><TableComposition /></TableProvider>

export default TableLprQcs;