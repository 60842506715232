import React from "react";
import { Tab } from "semantic-ui-react";

import TableScQcs from "../tables/TableScQcs";

const TabScQc = () => {
    
  return (
    <Tab.Pane>
      <TableScQcs />
    </Tab.Pane>
  );
};

export default TabScQc;