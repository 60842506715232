import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import moment from "moment";

import { Feed, Segment } from "semantic-ui-react";
import defaultProfile from '../../../images/default-profile.png';

import { fetchUserActivitiesByObjectId } from "../../../actions/administration/userActivitiesAction";

const FeedUserActivity = ({ objectId, defaultUser, defaultUserImage }) => {
    const uaData = useSelector(state => state.userActivities);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    React.useEffect(() => {
        dispatch(fetchUserActivitiesByObjectId(objectId));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUser = (activity) => {
        if (activity.userName){
          return activity.userName;
        } else if (defaultUser){
            return defaultUser;
        } else {
          return t('common.labels.unknown');
        }
      }

    return (
        <Segment basic>
            <Feed>
                {uaData.loading && 
                    <Feed.Event>
                        <Feed.Content>
                            <Feed.Extra text>
                                {t('common.labels.loadingData')}
                            </Feed.Extra>
                        </Feed.Content>
                    </Feed.Event>
                }
                {!uaData.loading && uaData.list.length === 0 &&
                    <Feed.Event>
                        <Feed.Content>
                            <Feed.Extra text>
                                {t('common.labels.noDataFound')}
                            </Feed.Extra>
                        </Feed.Content>
                    </Feed.Event>
                }
                {!uaData.loading && !uaData.error && uaData.list.length > 0 && uaData.list.map(activity => {
                    return (
                        <Feed.Event key={activity.id}>
                            <Feed.Label image={activity.userImageUrl ? activity.userImageUrl : defaultUserImage} />
                            <Feed.Content>
                                <Feed.Summary>
                                    <Feed.User>{getUser(activity)}</Feed.User> {activity.operation.toLowerCase()}
                                    <Feed.Date>{activity.date ? moment(activity.date).fromNow() : ''}</Feed.Date>
                                </Feed.Summary>
                                <Feed.Extra text>
                                    {activity.description}
                                </Feed.Extra>
                            </Feed.Content>
                        </Feed.Event>
                    );
                })}
            </Feed>
        </Segment>
    );
}

FeedUserActivity.defaultProps = {
    defaultUserImage: defaultProfile
};

FeedUserActivity.propTypes = {
    objectId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]).isRequired,
      defaultUser: PropTypes.string,
      defaultUserImage: PropTypes.string
}

export default FeedUserActivity;