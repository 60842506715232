import quercusApi from '../../apis/quercusApi';
import { handleError } from '../../helpers/handleError';
import {
    SC_FETCH_VERSION,
    SC_FETCH_VERSION_REQUEST,
    SC_FETCH_VERSION_ERROR,
    SC_GET_SERIAL_NUMBER,
    SC_GET_SERIAL_NUMBER_REQUEST,
    SC_GET_SERIAL_NUMBER_ERROR,
    SC_INCREMENT_BATCH,
    SC_INCREMENT_BATCH_REQUEST,
    SC_INCREMENT_BATCH_ERROR
} from './types';

export const fetchScApiVersion = () => async (dispatch) => {
    dispatch({ type: SC_FETCH_VERSION_REQUEST });
    try {
        const response = await quercusApi.get('/sc2/');
        dispatch({ type: SC_FETCH_VERSION, payload: response.data.version });
    } catch (error) {
        dispatch({ type: SC_FETCH_VERSION_ERROR, payload: handleError(error) });
    }
};

export const incrementBatch = () => async (dispatch) => {
    dispatch({ type: SC_INCREMENT_BATCH_REQUEST });
    try {
        const response = await quercusApi.post('/sc2/batch');
        dispatch({ type: SC_INCREMENT_BATCH, payload: response.data.batch });
        dispatch(getNextSerialNumber());
    } catch (error) {
        dispatch({ type: SC_INCREMENT_BATCH_ERROR, payload: handleError(error) });
    }
};

export const getNextSerialNumber = () => async (dispatch) => {
    dispatch({ type: SC_GET_SERIAL_NUMBER_REQUEST });
    try {
        const response = await quercusApi.get('/sc2/serial-numbers');
        dispatch({ type: SC_GET_SERIAL_NUMBER, payload: response.data });
    } catch (error) {
        dispatch({ type: SC_GET_SERIAL_NUMBER_ERROR, payload: handleError(error) });
    }
};