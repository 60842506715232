import React from "react";
import { Tab } from "semantic-ui-react";
import TableProducts from "../tables/TableProducts";

const TabProducts = () => {
  return (
    <Tab.Pane>
      <TableProducts/>
    </Tab.Pane>
  );
};

export default TabProducts;