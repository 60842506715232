import React from "react";
import { Tab } from "semantic-ui-react";
import TableLprTypes from "../tables/TableLprTypes";

const TabLprTypes = () => {
  return (
    <Tab.Pane>
      <TableLprTypes />
    </Tab.Pane>
  );
};

export default TabLprTypes;