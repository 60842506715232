import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { saveAs } from "file-saver";

import { useTable, TableProvider } from "../../../providers/tableProvider";
import { useAlert } from "../../../providers/alertsProvider";
import { useLoader } from "../../../providers/loaderProvider";
import TablePaginated from "../../core/TablePaginated";
import TableCellActions from "../../core/TableCellActions";
import Filter from "../../core/Filter";

import { fecthProductions } from "../../../actions/spotControl/scProductionsAction";
import { getDownloadFile } from "../../../helpers/handleDownloadFile";

function TableComposition () {
    let table = useTable();
    const scProductions = useSelector(state => state.scProductions);
    const dispatch = useDispatch();
    const { addError } = useAlert();
    const { showLoader, hideLoader } = useLoader();
    const { t } = useTranslation();
    
    React.useEffect(() => {
      loadProductions();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table.filters]);

    React.useEffect(() => {
      if (scProductions.error){
        addError(scProductions.error.error, scProductions.error.message);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scProductions.error]);

    const loadProductions = () => {
      dispatch(fecthProductions(table.filters));
    }

    const onLogsClick = (productionId) => {
      const url = `/sc2/productions/${productionId}/logs`;
      showLoader(t('sc2Ps.productions.actions.downloadLogsLoader'));
      getDownloadFile(url)
        .then(response => {
          saveAs(response.data, `logs_sc2_production${productionId}.zip`);
          hideLoader();
        })
        .catch(error => {
          addError(t('sc2Ps.productions.actions.errorDownloadLogs'), `${t('sc2Ps.productions.actions.errorMsgDownloadLogs')} ${productionId}`);
          hideLoader();
        });
    };

    const getActions = (disabledLogs) => {
      return [
        { key: 'logs', label: t('sc2Ps.productions.actions.downloadLogs'), icon: 'file alternate outline', handleClick: onLogsClick, disabled: disabledLogs }
      ];
    }

    const filters = [
      { key: 1, text: t('sc2Ps.productions.filter.serialNumber'), value: 'serialNumber', field: 'serialNumber', placeholder: '0010001', type: 'input' },
      { key: 2, text: t('sc2Ps.productions.filter.batchNumber'), value: 'batchNumber', field: 'batchNumber', placeholder: '0001', type: 'input' },
      { key: 3, text: t('sc2Ps.productions.filter.producer'), value: 'producer', field: 'producer', placeholder: t('sc2Ps.productions.filter.producerPlaceholder'), type: 'input' },
      { key: 4, text: t('sc2Ps.productions.filter.model'), value: 'model', field: 'model', type: 'select', options: [{ key: 1, text: 'Guidance', value: 'Guidance' }, { key: 2, text: 'Id', value: 'Id' }] },
      { key: 5, text: t('sc2Ps.productions.filter.numberCameras'), value: 'numCameras', field: 'numCameras', type: 'select', options: [{ key: 1, text: '1', value: '1' }, { key: 2, text: '2', value: '2' }] },
      { key: 6, text: t('sc2Ps.productions.filter.firmwareVersion'), value: 'fwVersion', field: 'fwVersion', placeholder: '2.7.0', type: 'input' },
      { key: 7, text: t('sc2Ps.productions.filter.productionDateBefore'), value: 'timestampBefore', field: 'timestamp', type: 'dateBefore' },
      { key: 8, text: t('sc2Ps.productions.filter.productionDateAfter'), value: 'timestampAfter', field: 'timestamp', type: 'dateAfter' },
      { key: 9, text: t('sc2Ps.productions.filter.productionDateBetween'), value: 'timestamp', field: 'timestamp', type: 'dateBetween'},
      { key: 10, text: t('sc2Ps.productions.filter.cameraVersion'), value: 'cameraVersion', field: 'cameraVersion', type: 'select', options: [{ key: 1, text: '1', value: '1'}, { key: 2, text: '2', value: '2' }]}
    ];

    const headerRow = [
      t('sc2Ps.productions.table.serialNumber'), 
      t('sc2Ps.productions.table.batchNumber'), 
      t('sc2Ps.productions.table.productionDate'), 
      t('sc2Ps.productions.table.producer'), 
      t('sc2Ps.productions.table.model'), 
      t('sc2Ps.productions.table.numCameras'),
      t('sc2Ps.productions.table.cameraVersion'), 
      t('sc2Ps.productions.table.fwVersion'), 
      t('common.labels.actions')];

    const renderBodyRow = ({ id, serialNumber, batchNumber, timestamp, producer, model, numCameras, cameraVersion, fwVersion, log }, i) => ({
      key: `row-${i}`,
      cells: [
        serialNumber || '-',
        batchNumber,
        timestamp ? moment(timestamp*1000).format('DD-MM-YYYY HH:mm:ss') : '-',
        producer,
        model,
        numCameras,
        cameraVersion,
        fwVersion,
        { key: 'actions', as: TableCellActions, actions: getActions(log && log.length > 0 ? false : true), rowId: id }
      ],
    });

    return (
      <div>
          <Filter
            filters={filters}
            onAddFilter={table.onResetFilter}
            onClearFilters={table.onClearFilters}
            loading={scProductions.loading}
          />
          <TablePaginated
            headerRow={headerRow}
            renderBodyRow={renderBodyRow}
            tableData={scProductions.productions}
            loading={scProductions.loading}
            totalItems={scProductions.totalItems}
            setPagination={table.onPaginationChange}
          />
      </div>
    );
}

const TableScProductions = () => <TableProvider><TableComposition /></TableProvider>

export default TableScProductions;