import React from "react";
import { Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

const TableBasic = ({ headerRow, renderBodyRow, tableData, footerRow, loading, collapsing, striped }) => {
  const { t } = useTranslation();

  const renderInlineRow = ({ label }, i) => ({
    key: `row-${i}`,
    cells: [
      label
    ],
  });

  const buildBodyRow = () => {
    if (loading || !tableData || tableData.length <= 0) return renderInlineRow;
    return renderBodyRow;
  }

  const getData = () => {
    if (loading) return [{ label: t('common.labels.loadingData') }];
    if (!tableData || tableData.length <= 0) return [{ label: t('common.labels.noData') }];
    return tableData;
  }

  return (
    <Table
      striped={striped}
      celled
      collapsing={collapsing}
      headerRow={headerRow}
      renderBodyRow={buildBodyRow()}
      tableData={getData()}
      footerRow={footerRow}
    />
  );
};

TableBasic.defaultProps = {
  striped: false,
  collapsing: false,
};

TableBasic.propTypes = {
  headerRow: PropTypes.array.isRequired,
  renderBodyRow: PropTypes.func.isRequired, 
  tableData: PropTypes.array, 
  footerRow: PropTypes.object.isRequired, 
  loading: PropTypes.bool.isRequired,
  collapsing: PropTypes.bool,
  striped: PropTypes.bool
}

export default TableBasic;