import React from "react";
import history from "../../history";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Grid, Header, Label, Tab, Segment } from "semantic-ui-react";
import { hasRole } from "../../utils/userRolesUtils";
import moment from "moment";

import { fetchRelease } from "../../actions/releases/releasesAction";
import TableFiles from "../../components/releases/tables/TableFiles";
import FeedUserActivity from "../../components/administration/feeds/FeedUserActivity";
import pipelineProfile from "../../images/pipeline-profile.png"

import Page from "../Page";

const ReleaseDetails = () => {
  const { releaseId } = useParams();
  const [release, setRelease] = React.useState({});
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authData = useSelector(state => state.auth);
  const rsData = useSelector(state => state.releases);
  const { roles } = authData.user;

  const initialRender = React.useRef(true);

  React.useEffect(() => {
    if (rsData.releases[releaseId]){
      showRelease(rsData.releases[releaseId]);
    } else {
      dispatch(fetchRelease(releaseId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (initialRender.current){
      initialRender.current = false;
    } else if (!initialRender.current){
      showRelease(rsData.releases[releaseId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rsData.releases]);

  React.useEffect(() => {
    if (rsData.error){
      history.push("/resourceNotFound");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rsData.error]);

  const showRelease = (rs) => {
    if (rs){
      if (hasRole(roles, 'releases', 'public') && rs.status.toUpperCase() !== "PRODUCTION"){
        history.push("/unauthorizedAccess");
      } else {
        setRelease(rs);
      }
    } else {
      history.push("/resourceNotFound");
    }
  }

  const sections = [
    { key: 'Dashboard', content: t('breadcrumb.dashboard'), link: true, as: Link, to: '/'},
    { key: 'releases', content: t('breadcrumb.releases'), link: true, as: Link, to: '/releases' },
    { key: 'product', content: release.productDescription },
    { key: 'package', content: release.packageDescription },
    { key: 'version', content: release.version, active: true }
  ];

  const statusList = {
    "QA": 'green', 
    "STAGE": 'yellow',
    "PRODUCTION": "blue"
  };

  const panes = [
    { 
      menuItem: { key: 'files', icon: 'cloud download', content: t('releases.details.tabs.files') }, 
      render: () => <Tab.Pane><TableFiles releaseId={releaseId}/></Tab.Pane> 
    },
    { 
      menuItem: { key: 'history', icon: 'history', content: t('releases.details.tabs.history') }, 
      render: () => <Tab.Pane><FeedUserActivity objectId={releaseId} defaultUser={t('common.labels.pipeline')} defaultUserImage={pipelineProfile}/></Tab.Pane> }
  ]
    
  return (
    <Page sections={sections}>
      <Header as='h3' attached='top' inverted color='grey'>
        {t('releases.details.title')}
      </Header>
      <Segment attached loading={rsData.loading}>
        <Grid>
          <Grid.Row>
              <Grid.Column width={2}>
                <Header as='h4'>{t('releases.details.product')}</Header>
              </Grid.Column>
              <Grid.Column width={13}>
                  {release.productDescription}
              </Grid.Column>
          </Grid.Row>
          <Grid.Row>
              <Grid.Column width={2}>
                <Header as='h4'>{t('releases.details.package')}</Header>
              </Grid.Column>
              <Grid.Column width={13}>
                  {release.packageDescription}
              </Grid.Column>
          </Grid.Row>
          <Grid.Row>
              <Grid.Column width={2}>
                <Header as='h4'>{t('releases.details.version')}</Header>
              </Grid.Column>
              <Grid.Column width={13}>
                  {release.version}
              </Grid.Column>
          </Grid.Row>
          <Grid.Row>
              <Grid.Column width={2}>
                <Header as='h4'>{t('releases.details.status')}</Header>
              </Grid.Column>
              <Grid.Column width={13}>
                {release.status && 
                  <Label key={release.status.toUpperCase()} color={statusList[release.status.toUpperCase()]}>{release.status.toUpperCase()}</Label>}
              </Grid.Column>
          </Grid.Row>
          <Grid.Row>
              <Grid.Column width={2}>
                <Header as='h4'>{t('releases.details.statusChangeDate')}</Header>
              </Grid.Column>
              <Grid.Column width={13}>
                {release.statusChangeDate ? moment(release.statusChangeDate).format('DD-MM-YYYY HH:mm:ss') : '-'}
              </Grid.Column>
          </Grid.Row>
          <Grid.Row>
              <Grid.Column width={2}>
                <Header as='h4'>{t('releases.details.releaseCandidate')}</Header>
              </Grid.Column>
              <Grid.Column width={13}>
                {`RC${release.releaseCandidate}`}
              </Grid.Column>
          </Grid.Row>
          <Grid.Row>
              <Grid.Column width={2}>
                <Header as='h4'>{t('releases.details.releaseDate')}</Header>
              </Grid.Column>
              <Grid.Column width={13}>
                {release.releaseDate ? moment(release.releaseDate).format('DD-MM-YYYY HH:mm:ss') : '-'}
              </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <Tab panes={panes} style={{ marginTop: '1em' }}/>
    </Page>
  );
};

export default ReleaseDetails;